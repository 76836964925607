import AOS from 'aos';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import TypingEffect from '../utils/typing';
AOS.init();

export default function CekKhodam() {
    const texts = ["Khodam", "Jodoh", "Nasib"];

    const [searchQuery, setSearchQuery] = useState('');
    const [khodam, setKhodam] = useState('');

    const khodamList = [
        "Macan Putih", "Sayur Kangkung", "Ikan Teri", "Raksasa Batu",
        "Motor Butut", "Panci Bolong", "Banteng PDI", "Semut Api", "Kera Sakti"
    ];

    const handleSearch = () => {
        if (searchQuery) {
            const randomKhodam = khodamList[Math.floor(Math.random() * khodamList.length)];
            setKhodam(randomKhodam);
        } else {
            setKhodam('');
        }
    };
    return (
        <>
            <Helmet>
                <title>Cek Khodam - Wafi</title>
                <meta name="description" content="Preview of WarungDenWafi UI" />
            </Helmet>
            <main className="font-jakartasans max-w-7xl mx-auto h-screen w-screen dark:bg-[#121212]">
                <div className='flex flex-col justify-center items-center min-h-screen space-y-4'>
                    <h1 className='font-semibold text-4xl'>Cek <TypingEffect texts={texts} /></h1>
                    <div className="flex items-center">
                        <input
                            type="text"
                            className="border rounded-l-md px-3 py-2 w-64"
                            placeholder="Nama..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <button
                            className="bg-blue-500 text-white px-4 py-2 rounded-r-md hover:bg-blue-600"
                            onClick={handleSearch}
                        >
                            Cek
                        </button>
                    </div>
                    {khodam && (
                        <div className='mt-4 text-xl text-center'>
                            <p>Khodam Anda adalah: <strong>{khodam}</strong></p>
                        </div>
                    )}
                </div>
            </main>
        </>
    )
}