export default function ChatInput({placeHolder, newMessage, setNewMessage, Update, sendMessage, isSending, handleUpdate, idUpdate, edited}) {
    return (
        <div className='py-3 flex gap-3 w-full'>
            <input
                placeholder={!placeHolder ? "Type a messsage" : "Edit a message"}
                className="w-full px-4 py-2 border border-[#ccc] rounded-md dark:border-none dark:bg-[#262626] dark:text-[#fafafa] focus:outline-none focus:ring-0 focus:border-[#ccc] dark:focus:border-none"
                type="text"
                value={newMessage}
                onChange={e => setNewMessage(e.target.value)}
            />
            {!Update ?
                <button className='bg-[#525252] dark:bg-[#262626] rounded-[10px] p-3' onClick={sendMessage} disabled={isSending} >
                    <svg className='fill-[#fafafa]' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="m21.426 11.095-17-8A1 1 0 0 0 3.03 4.242l1.212 4.849L12 12l-7.758 2.909-1.212 4.849a.998.998 0 0 0 1.396 1.147l17-8a1 1 0 0 0 0-1.81z"></path></svg>
                </button> :
                <button className='bg-[#525252] dark:bg-[#262626] rounded-[10px] p-3' onClick={() => { handleUpdate(idUpdate, newMessage, edited) }} disabled={isSending} >
                    <svg className='fill-[#fafafa]' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="m21.426 11.095-17-8A1 1 0 0 0 3.03 4.242l1.212 4.849L12 12l-7.758 2.909-1.212 4.849a.998.998 0 0 0 1.396 1.147l17-8a1 1 0 0 0 0-1.81z"></path></svg>
                </button>
            }
        </div>
    )
}