import React, { useState, useEffect } from 'react';

function TypingEffect({ texts, typingSpeed = 250, backSpeed = 100, pauseDuration = 1000 }) {
    const [displayedText, setDisplayedText] = useState('');
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [currentCharIndex, setCurrentCharIndex] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        let typingTimeout;
        if (isDeleting) {
            if (currentCharIndex > 0) {
                typingTimeout = setTimeout(() => {
                    setDisplayedText((prev) => prev.slice(0, -1));
                    setCurrentCharIndex((prev) => prev - 1);
                }, backSpeed);
            } else {
                setIsDeleting(false);
                setCurrentTextIndex((prev) => (prev + 1) % texts.length);
            }
        } else {
            if (currentCharIndex < texts[currentTextIndex].length) {
                typingTimeout = setTimeout(() => {
                    setDisplayedText((prev) => prev + texts[currentTextIndex][currentCharIndex]);
                    setCurrentCharIndex((prev) => prev + 1);
                }, typingSpeed);
            } else {
                typingTimeout = setTimeout(() => {
                    setIsDeleting(true);
                }, pauseDuration);
            }
        }

        return () => clearTimeout(typingTimeout);
    }, [currentCharIndex, isDeleting, currentTextIndex, texts, typingSpeed, backSpeed, pauseDuration]);

    return (
        <span className="typing-effect">
            {displayedText}
            <span className="cursor">|</span>
        </span>
    );
}

export default TypingEffect;