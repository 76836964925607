import React, { useState, useRef, useEffect, Suspense } from 'react';
import ButtonGoogle from './ButtonGoogle';
import PromptAI from './PromptAI';
import ChatUser from './ChatUser';
import ChatAI from './ChatAI';
import { onAuthStateChanged } from 'firebase/auth';
import { app, auth } from '../../api/firebase';
import { Base64 } from 'js-base64';
import { requestGroqAI } from '../../utils/groq'
import { addDoc, collection, getFirestore, onSnapshot, orderBy, query, serverTimestamp } from 'firebase/firestore';
import LoadingGroqAI from './LoadingGroqAI';
const SuspenseChatAI = React.lazy(() => import('../FloatingButton/ChatAI'));
const db = getFirestore(app)

export default function ButtonAI() {
    const currentDate = new Date();
    const [user, setUser] = useState(null)
    const [message, setMessage] = useState("")
    const [dataMessage, setDataMessage] = useState([])
    const [isSending, setIsSending] = useState(false);
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [responGroqAI, setResponGroqAI] = useState(false);
    const chatBoxRef = useRef(null);

    const toggleChat = () => {
        setIsChatOpen(!isChatOpen);
    };

    const lastMessageRef = useRef(null);

    useEffect(() => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [dataMessage]);

    useEffect(() => {
        if (user) {
            const userBase64 = Base64.encode(user.email);
            const pathBase64 = 'ChatAI - ' + userBase64;
            const collectionRef = collection(db, pathBase64);
            const index = query(collectionRef, orderBy("timestamp"));
            const unmessage = onSnapshot(index, snapshot => {
                setDataMessage(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            });

            return () => unmessage();
        }
    }, [user]);

    useEffect(() => {
        onAuthStateChanged(auth, user => {
            if (user) {
                setUser(user)
            } else {
                setUser(null)
            }
        })
    }, [])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (chatBoxRef.current && !chatBoxRef.current.contains(event.target)) {
                setIsChatOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [chatBoxRef]);

    const sendDataCommand = async (messageText, roleText) => {
        if (!message.trim()) {
            alert('Please fill in the message field!');
            return;
        }
        setIsSending(true);
        let dataToSend = {
            email: user.email,
            uid: user.uid,
            photoURL: user.photoURL,
            displayName: user.displayName,
            text: messageText,
            date: currentDate,
            role: roleText,
            timestamp: serverTimestamp()
        };
        const userBase64 = Base64.encode(user.email);
        const pathBase64 = 'ChatAI - ' + userBase64;
        const collectionRef = collection(db, pathBase64);
        await addDoc(collectionRef, dataToSend);
        setIsSending(false);
    };

    const dataGroqAI = async () => {
        await sendDataCommand(message, "user");
        setResponGroqAI(true);
        const GroqAI = await requestGroqAI(message);
        setMessage("");
        await sendDataCommand(GroqAI, "bot");
        setResponGroqAI(false);
    };

    return (
        <div className="fixed bottom-4 right-4 z-50">
            <div
                className={`floating-chat ${isChatOpen ? 'hidden' : 'flex'
                    } items-center justify-center w-12 h-12 bg-[#525252] rounded-full text-white cursor-pointer dark:bg-[#262626]`}
                onClick={toggleChat}
            >
                <svg className='w-7' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="#ffffff" d="M320 0c17.7 0 32 14.3 32 32V96H472c39.8 0 72 32.2 72 72V440c0 39.8-32.2 72-72 72H168c-39.8 0-72-32.2-72-72V168c0-39.8 32.2-72 72-72H288V32c0-17.7 14.3-32 32-32zM208 384c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H208zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H304zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H400zM264 256a40 40 0 1 0 -80 0 40 40 0 1 0 80 0zm152 40a40 40 0 1 0 0-80 40 40 0 1 0 0 80zM48 224H64V416H48c-26.5 0-48-21.5-48-48V272c0-26.5 21.5-48 48-48zm544 0c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H576V224h16z" /></svg>
            </div>
            <div
                className={`chat ${isChatOpen ? 'flex' : 'hidden'
                    } fixed bottom-4 p-3 right-4 outline outline-1 outline-[#d4d4d4] w-96 h-[450px] bg-[#fafafa] rounded-lg shadow-lg flex-col transition-transform transform dark:bg-[#121212] dark:outline-[#404040] max-[500px]:w-80 max-[350px]:w-72 ${isChatOpen ? 'scale-100' : 'scale-0'
                    }`}
                ref={chatBoxRef}
            >
                <div className="header flex items-center justify-between pb-3 bg-[#fafafa] rounded-lg dark:bg-[#121212]">
                    <div className='flex items-center gap-2'>
                        <svg className='w-6 fill-black dark:fill-white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M320 0c17.7 0 32 14.3 32 32V96H472c39.8 0 72 32.2 72 72V440c0 39.8-32.2 72-72 72H168c-39.8 0-72-32.2-72-72V168c0-39.8 32.2-72 72-72H288V32c0-17.7 14.3-32 32-32zM208 384c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H208zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H304zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H400zM264 256a40 40 0 1 0 -80 0 40 40 0 1 0 80 0zm152 40a40 40 0 1 0 0-80 40 40 0 1 0 0 80zM48 224H64V416H48c-26.5 0-48-21.5-48-48V272c0-26.5 21.5-48 48-48zm544 0c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H576V224h16z" /></svg>
                        <span className="text-black font-medium text-sm mt-1 dark:text-white">Chat With AI</span>
                    </div>
                    <button className="close-chat bg-transparent border-0 text-white text-xl cursor-pointer" onClick={toggleChat}>
                        <svg className='w-4 fill-black dark:fill-white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                    </button>
                </div>
                <hr className='dark:border-[#404040]' />
                <div className='overflow-y-auto h-[60vh] pb-[17vh] pt-5 px-1 max-[900px]:pb-[15vh] max-[500px]:pb-[17vh]'>
                    <ChatAI
                        user="Wafi'AI"
                        msg="Welcome! Please feel free to ask me anything, I' m here to help."

                    />
                    {user ? (
                        <>
                            {dataMessage.map((msg, index) => (
                                <div key={index} ref={index === dataMessage.length - 1 ? lastMessageRef : null}>
                                    {msg.data.role === 'bot' ? (
                                        <React.Suspense fallback={<LoadingGroqAI />}>
                                            <SuspenseChatAI msg={msg.data.text} user="Wafi'AI" />
                                        </React.Suspense>
                                    ) : (
                                        <>
                                            <ChatUser
                                                msg={msg.data.text}
                                                photoURL={msg.data.photoURL}
                                                user={msg.data.displayName}
                                            />
                                            {responGroqAI ? <LoadingGroqAI user="Wafi'AI" /> : null}
                                        </>
                                    )}
                                </div>
                            ))}
                            <div className="fixed bottom-0 left-0 w-full bg-[#fafafa] shadow-lg pb-3 px-3 rounded-lg dark:bg-[#121212]">
                                <hr className='dark:border-[#404040]' />
                                <PromptAI
                                    sendDataCommand={dataGroqAI}
                                    isSending={isSending}
                                    message={message}
                                    setMessage={setMessage}
                                />
                                <ButtonGoogle
                                    user={user}
                                />
                            </div>
                        </>
                    ) : (
                        <div className="fixed bottom-0 left-0 w-full bg-[#fafafa] shadow-lg pb-3 px-3 rounded-lg dark:bg-[#121212]">
                            <hr className='dark:border-[#404040]' />
                            <ButtonGoogle />
                        </div>
                    )}
                </div>
            </div>
        </div >
    );
};