import clsx from "clsx";
import { useState } from "react";

export default function ChatUser({ msg, photoURL, user }) {
    const [Render, setRender] = useState(true);

    return (
        <div className='flex gap-2 justify-end py-5'>
            <div className='flex flex-col justify-end items-end pl-12'>
                <h1 className='font-[500] text-black dark:text-white'>{user}</h1>
                <div class="text-[14px] w-fit gap-2 mt-1 rounded-xl rounded-tr-none bg-[#e5e5e5] dark:bg-[#262626] px-3 py-1 dark:text-white max-[375px]:text-[14px] max-[425px]:text-[15px] max-[600px]:text-[16px]">
                    {msg}
                </div>
            </div>
            <div
                className={clsx(
                    "order-2 flex-shrink-0 mt-3",
                    Render ? 'animate-pulse' : ''
                )}
            >
                <img
                    alt='imguser'
                    className={clsx(
                        'w-10 rounded-full duration-700 ease-in-out max-[390px]:w-10',
                        Render ? 'scale-[1.02] blur-sm grayscale' : 'scale-100 blur-0 grayscale-0'
                    )}
                    onLoad={() => setRender(false)}
                    loading='lazy'
                    src={photoURL} />
            </div>
        </div>
    )
}