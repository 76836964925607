import React from "react";
import Skill from '../component/about/skill'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from "react-helmet";
AOS.init();

export default function About() {
    return (
        <>
            <Helmet>
                <title>About - Wafi</title>
                <meta name="description" content="Muhammad Wafi, UI/UX Engineer" />
            </Helmet>
            <div className="relative max-w-6xl mx-auto px-8 mt-8 font-sora max-[768px]:mt-28" data-aos='fade-up'>
                <div className='hidden alamp dark:block absolute z-0 w-full -top-40 blue__gradient' />
                <div className="pb-6 flex items-center text-3xl">
                    <svg className="w-10 mr-2 icon-responsive dark:fill-[#E4E6EB]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 2a5 5 0 1 0 5 5 5 5 0 0 0-5-5zm0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm9 11v-1a7 7 0 0 0-7-7h-4a7 7 0 0 0-7 7v1h2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1z"></path></svg>
                    <h1 className="font-bold texttitle-responsive">About me</h1>
                </div>
                <div className="flex items-start max-[768px]:flex-col">
                    <div className="flex-1 pr-[70px] text-[17px] textparagraf-responsive max-[768px]:pr-0 dark:text-[#a3a3a3]">
                        <p className="pb-[15px]">
                            I'm Muhammad Wafi. UI/UX Engineer, I have skills in UI/UX Design and Website Development, both which I learned at school and taught myself via the internet.
                        </p>
                        <p className="pb-[15px]">
                            At school I chose the Visual Communication Design major in the UI/UX Design division, even though my division is UI/UX Design, I also studied developing a website, there are still few languages that I master and learn, even so, I am very happy to be able to develop this website.
                        </p>
                        <p className="pb-[15px]">
                            Time after time I will learn more about both because I am a person who likes to learn things and always wants to learn new things.
                        </p>
                    </div>
                    <div className="max-[768px]:mt-6">
                        <div>
                            <h1 className="font-semibold text-2xl mb-2 texttitle-responsive dark:text-[#E4E6EB]">Education</h1>
                            <p className="text-[17px] -tracking-tighter textparagraf-responsive dark:text-[#a3a3a3]">MTs Al-Falah Margoyoso | 2020 - 2023</p>
                            <p className="text-[17px] -tracking-tighter textparagraf-responsive dark:text-[#a3a3a3]">SMK Raden Umar Said Kudus | Now</p>
                        </div>
                        <div>
                            <h1 className="font-semibold text-2xl mt-10 mb-2 texttitle-responsive dark:text-[#E4E6EB]">Work</h1>
                            <p className="text-[17px] -tracking-tighter textparagraf-responsive dark:text-[#a3a3a3]">Not Working Yet</p>
                        </div>
                    </div>
                </div>
                <hr className="my-10 border-[#d1d5db] dark:border-[#404040]"></hr>
                <div className="Skill">
                    <div className="Title mb-7 flex items-center text-3xl font-bold">
                        <svg xmlns="http://www.w3.org/2000/svg" className="dark:fill-[#E4E6EB] mr-2 w-9 icon-responsive" viewBox="0 0 640 512"><path d="M392.8 1.2c-17-4.9-34.7 5-39.6 22l-128 448c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l128-448c4.9-17-5-34.7-22-39.6zm80.6 120.1c-12.5 12.5-12.5 32.8 0 45.3L562.7 256l-89.4 89.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-112-112c-12.5-12.5-32.8-12.5-45.3 0zm-306.7 0c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l112 112c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256l89.4-89.4c12.5-12.5 12.5-32.8 0-45.3z" /></svg>
                        <h1 className="texttitle-responsive dark:text-[#E4E6EB]">Skills</h1>
                    </div>
                    <Skill />
                </div>
            </div>
        </>
    )
}
