import React, { useState } from 'react';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../../api/firebase'
import Modal from './modal';

export default function ButtonGoogle({ user }) {
    const [modalOpen, setModalOpen] = useState(false);
    const handleGoogleLogin = async () => {
        const provider = new GoogleAuthProvider()
        try {
            await signInWithPopup(auth, provider)
            window.location.reload();
        } catch (error) {
            console.log(error)
        }
    }
    const handleGoogleLogout = async () => {
        try {
            auth.signOut()
            window.location.reload();
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            {user ? (
                <div className="flex justify-center items-center pt-10">
                    <button
                        onClick={() => setModalOpen(true)}
                        className="bg-[#525252] text-sm font-sora flex gap-3 text-white py-2 px-4 rounded-md focus:outline-none focus:shadow-outline dark:bg-[#262626]"
                    >
                        Give a Review
                        <svg className="w-4 mt-[3px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="#fafafa" d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" /></svg>
                    </button>
                    <Modal user={user} isOpen={modalOpen} onClose={() => setModalOpen(false)} handleGoogleLogout={handleGoogleLogout} />
                </div>
            ) : (
                <div className='pt-5 w-full space-y-2'>
                    <p className='text-sm text-center text-[#404040] dark:text-[#a3a3a3]'>Please log in to leave a review</p>
                    <div className='flex justify-center'>
                        <button className='flex gap-3 font-[400] items-center rounded-lg bg-[#ffffff] px-4 py-2 font-sora text-[15px]  transition-all duration-300 dark:bg-[#fafafa] mb-2 mt-2 w-full justify-center border border-[#e5e5e5] shadow-sm hover:scale-[101%]' onClick={handleGoogleLogin}>
                            <svg className='w-6' stroke="currentColor" fill="currentColor" stroke-width="0" version="1.1" x="0px" y="0px" viewBox="0 0 48 48" enable-background="new 0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12
	c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24
	c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657
	C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36
	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571
	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path></svg>
                            <span className='text-sm'>Sign in with Google</span>
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}