import React, { useState, useEffect, Suspense } from 'react'
import { onAuthStateChanged } from 'firebase/auth'
import { getFirestore, onSnapshot, collection, addDoc, orderBy, query, serverTimestamp, doc, deleteDoc, updateDoc } from 'firebase/firestore'
import { auth, app } from '../api/firebase'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Loader from '../component/chat/skeleton';
import ButtonGoogle from '../component/chat/ButtonGoogle'
import ChatInput from '../component/chat/ChatInput'
import { Helmet } from 'react-helmet';
AOS.init();
const CardChatSignOut = React.lazy(() => import('../component/chat/ChatSignOut'));
const CardChatSignIn = React.lazy(() => import("../component/chat/ChatSignIn"));
const db = getFirestore(app)

export default function App() {

    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();

    const messageDate = `${day}/${month}/${year}, ${hours}:${minutes}`;

    const [user, setUser] = useState(null)
    const [messages, setMessages] = useState([])
    const [newMessage, setNewMessage] = useState("")
    const [isSending, setIsSending] = useState(false);
    const [Update, setUpdate] = useState(false);
    const [idUpdate, setidUpdate] = useState("");
    const [edited, setEdited] = useState("");
    const [placeHolder, setPlaceHolder] = useState(false);

    useEffect(() => {
        const index = query(collection(db, "messages"), orderBy("timestamp"))
        const unmessage = onSnapshot(index, snapshot => {
            setMessages(snapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
        return unmessage
    }, [])

    useEffect(() => {
        onAuthStateChanged(auth, user => {
            if (user) {
                setUser(user)
            } else {
                setUser(null)
            }
        })
    }, [])

    const sendMessage = async () => {
        if (!newMessage.trim()) {
            alert('Please fill in the message field!');
            return;
        }
        setIsSending(true);
        let dataToSend = {};

        if (user.email === process.env.REACT_APP_AUTHOR) {
            dataToSend = {
                author: 'on',
                email: user.email,
                uid: user.uid,
                photoURL: user.photoURL,
                displayName: user.displayName,
                text: newMessage,
                date: messageDate,
                edited: edited,
                timestamp: serverTimestamp()
            };
        } else {
            dataToSend = {
                email: user.email,
                uid: user.uid,
                photoURL: user.photoURL,
                displayName: user.displayName,
                text: newMessage,
                date: messageDate,
                edited: edited,
                timestamp: serverTimestamp()
            };
        }

        await addDoc(collection(db, "messages"), dataToSend);
        setIsSending(false);
        setNewMessage("");
    };

    const handleDelete = async (id) => {
        try {
            const deleteMessage = doc(db, 'messages', id)
            await deleteDoc(deleteMessage)
        } catch (error) {
            console.log(error)
        }
    }

    const handleUpdate = async (id, newData, edited) => {
        try {
            const documentRef = doc(db, 'messages', id);

            await updateDoc(documentRef, {
                text: newData,
                edited: edited
            });

        } catch (error) {
            console.error(error);
        }
        setPlaceHolder(false)
        setUpdate(false)
        setNewMessage("");
        setEdited('')
    };

    const handleEdit = async (id, editMessage) => {
        setidUpdate(id)
        setEdited('yes')
        setNewMessage(editMessage)
        setPlaceHolder(true)
        setUpdate(true)
    }

    return (
        <>
            <Helmet>
                <title>Chat - Wafi</title>
                <meta name="description" content="Welcome to the chat room, where you can leave questions, suggestions or anything else." />
            </Helmet>
            <div className='max-w-6xl mx-auto px-8 mt-8 relative font-poppins max-[769px]:mt-28' data-aos='fade-up'>
                <div className='hidden plamp dark:block absolute z-0 w-full -top-40 blue__gradient' />
                <div className="">
                    <div className="FlexProject flex items-center gap-1">
                        <svg className='w-11 scale-x-[-1] fill-[#121212] max-[375px]:w-9 max-[425px]:w-9 max-[600px]:w-10 dark:fill-white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M123.6 391.3c12.9-9.4 29.6-11.8 44.6-6.4c26.5 9.6 56.2 15.1 87.8 15.1c124.7 0 208-80.5 208-160s-83.3-160-208-160S48 160.5 48 240c0 32 12.4 62.8 35.7 89.2c8.6 9.7 12.8 22.5 11.8 35.5c-1.4 18.1-5.7 34.7-11.3 49.4c17-7.9 31.1-16.7 39.4-22.7zM21.2 431.9c1.8-2.7 3.5-5.4 5.1-8.1c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208s-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6c-15.1 6.6-32.3 12.6-50.1 16.1c-.8 .2-1.6 .3-2.4 .5c-4.4 .8-8.7 1.5-13.2 1.9c-.2 0-.5 .1-.7 .1c-5.1 .5-10.2 .8-15.3 .8c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c4.1-4.2 7.8-8.7 11.3-13.5c1.7-2.3 3.3-4.6 4.8-6.9c.1-.2 .2-.3 .3-.5z" /></svg>
                        <h1 className='font-bold text-[#121212] text-4xl mt-1 ml-2 max-[375px]:text-2xl max-[425px]:text-[22px] max-[600px]:text-[26px] dark:text-[#E4E6EB]'>Chat</h1>
                    </div>
                    <p className='mt-3 text-[17px] mb-2 text-[#525252] max-[375px]:text-[15px] max-[425px]:text-[16px] max-[600px]:text-[17px] dark:text-[#a3a3a3]'>Welcome to the chat room, where you can leave questions, suggestions or anything else.</p>
                    <hr className='border-[#d1d5db] dark:border-[#404040]' />
                </div>
                {user ? (
                    <div className='flex flex-col'>
                        <div className="w-full overflow-y-auto h-[60vh]">
                            {messages.map((msg) => (
                                <Suspense fallback={<Loader />}>
                                    <CardChatSignIn
                                        msg={msg}
                                        user={user}
                                        handleEdit={handleEdit}
                                        handleDelete={handleDelete}
                                    />
                                </Suspense>
                            ))}
                        </div>
                        <hr className='border-[#d1d5db] dark:border-[#404040]' />
                        <ChatInput
                            placeHolder={placeHolder}
                            newMessage={newMessage}
                            setNewMessage={setNewMessage}
                            sendMessage={sendMessage}
                            Update={Update}
                            isSending={isSending}
                            handleUpdate={handleUpdate}
                            idUpdate={idUpdate}
                            edited={edited}
                        />
                        <ButtonGoogle
                            user={user}
                        />
                    </div>
                ) : (
                    <div>
                        <div className='overflow-y-auto h-[60vh] py-5'>
                            {messages.map((msg) => (
                                <Suspense fallback={<Loader />}>
                                    <CardChatSignOut msg={msg} />
                                </Suspense>
                            ))}
                        </div>
                        <hr className='border-[#d1d5db] dark:border-[#404040]' />
                        <ButtonGoogle />
                    </div>
                )}
            </div >
        </>
    )
}