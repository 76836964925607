import React from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';

export default function ChatAI({ msg, user }) {
    return (
        <div className='flex gap-2'>
            <div className='flex justify-center mt-3 flex-shrink-0 bg-[#737373] w-10 h-10 rounded-full dark:bg-[#262626]'>
                <svg
                    className='w-6 fill-white'
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M320 0c17.7 0 32 14.3 32 32V96H472c39.8 0 72 32.2 72 72V440c0 39.8-32.2 72-72 72H168c-39.8 0-72-32.2-72-72V168c0-39.8 32.2-72 72-72H288V32c0-17.7 14.3-32 32-32zM208 384c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H208zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H304zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H400zM264 256a40 40 0 1 0 -80 0 40 40 0 1 0 80 0zm152 40a40 40 0 1 0 0-80 40 40 0 1 0 0 80zM48 224H64V416H48c-26.5 0-48-21.5-48-48V272c0-26.5 21.5-48 48-48zm544 0c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H576V224h16z"
                    /></svg>
            </div>
            <div className='flex flex-col pr-14'>
                <h1 className='font-[500] text-black dark:text-white'>{user}</h1>
                <p class="flex text-[14px] w-fit items-end gap-2 mt-1 rounded-xl rounded-tl-none bg-[#e5e5e5] dark:bg-[#262626] px-3 py-1 dark:text-white max-[375px]:text-[14px] max-[425px]:text-[15px] max-[600px]:text-[16px]">
                    <SyntaxHighlighter
                        customStyle={{ backgroundColor: 'transparent' }}
                        wrapLongLines
                    >{msg}</SyntaxHighlighter>
                </p>
            </div>
        </div>
    )
}