export const drawing = () => {
  const path = document.querySelector("#path1");
  const length = path.getTotalLength();

  const startAnimation = () => {
    path.style.transition = path.style.WebkitTransition = "none";
    path.style.strokeDasharray = length + " " + length;
    path.style.strokeDashoffset = length;
    path.getBoundingClientRect();

    setTimeout(() => {
      path.style.transition = path.style.WebkitTransition = "stroke-dashoffset 50s ease-in-out";
      path.style.strokeDashoffset = "0";
    }, 1000);
  };

  const stopAnimation = () => {
    path.style.transition = path.style.WebkitTransition = "none";
    path.style.strokeDashoffset = length;
  };

  startAnimation();

  window.addEventListener("beforeunload", () => {
    stopAnimation();
  });

  window.addEventListener("load", () => {
    startAnimation();
  });
};
