import React, { useEffect, useRef } from 'react';

function animateCounter(target, duration, targetValue) {
  const start = parseInt(target.innerText, 10);
  const startTime = performance.now();

  function updateCounter() {
    const now = performance.now();
    const progress = Math.min((now - startTime) / duration, 1);

    target.innerText = Math.floor(start + (progress * (targetValue - start)));

    if (progress < 1) {
      requestAnimationFrame(updateCounter);
    } else {
      target.innerText = targetValue;
    }
  }

  requestAnimationFrame(updateCounter);
}

export default function CounterComponent() {
  const totalProjectsRef = useRef(null);
  const targetValue = 8;
  const duration = 2000;
  const delay = 0;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && totalProjectsRef.current) {
          setTimeout(() => {
            animateCounter(totalProjectsRef.current, duration, targetValue);
          }, delay);
        }
      },
      { threshold: 0.5 }
    );

    if (totalProjectsRef.current) {
      observer.observe(totalProjectsRef.current);
    }

    return () => {
      if (totalProjectsRef.current) {
        observer.unobserve(totalProjectsRef.current);
      }
    };
  }, []);

  return (
    <div className="flex items-center max-[430px]:flex-col">
      <div className="font-medium max-[430px]:mb-5">
        <h1 className='font-semibold text-3xl max-[375px]:text-xl' ref={totalProjectsRef} id="totalProjects">9999</h1>
        <p className='font-normal max-[375px]:text-sm'>Project Completed</p>
      </div>
      <a href='/contact' className="flex items-center gap-3 ml-8 bg-[#fafafa] text-[#737373] px-5 py-3 rounded-md text-[14px] shadow-sm shadow-black transition-all duration-[.5s] hover:scale-[1.05] max-[430px]:ml-0 max-[375px]:text-[13px] dark:bg-transparent dark:border-[#fafafa] dark:border-[1px] dark:text-[#fafafa] dark:hover:bg-[#2dd4bf] dark:hover:border-[#2dd4bf] dark:hover:text-[#fafafa]">
        <h1 className='font-medium'>Contact Me!</h1>
      </a>
    </div>
  );
}
