import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

export default function Card(props) {
    const [Render, setRender] = useState(true);

    return (
        <>
            <div className='relative h-[385px] border-[1px] group border-[#e5e5e5] rounded-xl overflow-hidden cursor-pointer transition-all duration-[.5s] hover:scale-[1.03] dark:border-[#404040]'>
                <Link to={props.link} aria-label='linkproject'>{/*<div className='CardHover absolute z-[100] bg-transparent w-[350px] h-[400px]'></div>*/}<div className='BackgroundHoverT absolute z-[4] h-full w-full bg-black bg-opacity-[0.7] opacity-0 transition-opacity duration-[.5s] group-hover:opacity-80'></div>
                    <div className="flex flex-col items-center">
                        <div
                            className={clsx(
                                "absolute z-0 h-full w-full dark:bg-[#262626]",
                                Render ? 'animate-pulse' : '',
                            )}
                        ></div>
                        <img
                            className={clsx(
                                'absolute z-[1] transition-all rounded-xl group-hover:blur-[5px] duration-700 ease-in-out',
                                Render ? 'scale-[1.02] blur-xl grayscale' : 'scale-100 blur-0 grayscale-0'
                            )}
                            src={props.imgsrc}
                            alt="imgproject"
                            onLoad={() => setRender(false)}
                            loading='lazy' />
                        <div className='font-poppins absolute gap-3 top-[20%] flex z-[4] opacity-0 transition-opacity duration-[.5s] group-hover:opacity-100'>
                            <h1 className='text-white'>View Project</h1>
                            <img className='' src="icon/arrow-right-light.svg" alt="" />
                        </div>
                    </div>
                    <div className="font-sora bg-[#fafafa] h-full w-full absolute z-[6] mt-44 px-4 py-1 dark:bg-[#262626] dark:border-[#171717]">
                        <div className="flex gap-2 text-[11px] text-white pt-4">
                            <h1 className='flex justify-center bg-[#525252] py-[2px] px-[10px] rounded-xl dark:bg-transparent border dark:border-[#525252]'>{props.hastag1}</h1>
                            <h1 className='flex justify-center bg-[#525252] py-[2px] px-[10px] rounded-xl dark:bg-transparent border dark:border-[#525252]'>{props.hastag2}</h1>
                            <h1 className='flex justify-center bg-[#525252] py-[2px] px-[10px] rounded-xl dark:bg-transparent border dark:border-[#525252]'>{props.hastag3}</h1>
                        </div>
                        <div className="font-sora mt-2">
                            <div className='text-[#121212] leading-10 text-lg font-medium transition-all duration-[.5s] group-hover:underline dark:text-[#E4E6EB] dark:group-hover:text-[#2dd4bf]'>
                                <h1>{props.title}</h1>
                            </div>
                            <p className='text-sm text-[#404040] mt-1 dark:text-[#a3a3a3]'>{props.description}</p>
                            <div className="flex items-center mt-1 text-[#404040] dark:text-[#a3a3a3]">
                                <svg xmlns="http://www.w3.org/2000/svg" className='w-[18px] fill-[#404040] dark:fill-[#a3a3a3]' viewBox="0 0 24 24"><path d="M7 11h2v2H7zm0 4h2v2H7zm4-4h2v2h-2zm0 4h2v2h-2zm4-4h2v2h-2zm0 4h2v2h-2z"></path><path d="M5 22h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2zM19 8l.001 12H5V8h14z"></path></svg>
                                <h1 className='text-[12px] ml-1 mt-[4px] font-medium'>{props.date}</h1>
                            </div>
                            <div className="mt-3 w-[25px] flex gap-2">
                                <img src={props.imgsrctech1} className={props.imgclass1} alt="tech1" />
                                <img src={props.imgsrctech2} className={props.imgclass2} alt="tech1" />
                                <img src={props.imgsrctech3} className={props.imgclass3} alt="tech1" />
                                <img className={props.classnextjslight} src={props.nextjslight} alt="nextlight" />
                                <img className={props.classnextjsdark} src={props.nextjsdark} alt="nextdark" />
                                <img src={props.imgsrctech4} className={props.imgclass4} alt="tech4" />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </>
    )
}
