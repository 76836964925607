export const mode = () => {
    const darkToggle = document.querySelector('#dark-toggle')
    const html = document.querySelector('html')
    if (darkToggle.checked) {
        html.classList.add('dark')
        localStorage.theme = 'dark'
    } else {
        html.classList.remove('dark')
        localStorage.theme = 'light'
    }
}
