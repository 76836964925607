import { useEffect } from "react";

export default function Scroller() {
  useEffect(() => {
    const scrollers = document.querySelectorAll('.scroller');

    if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
      scrollers.forEach((scroller) => {
        scroller.setAttribute('data-animated', true);

        const scrollerInner = scroller.querySelector('.scroller__inner');

        Array.from(scrollerInner.children).forEach((item) => {
          const duplicatedItem = item.cloneNode(true);
          duplicatedItem.setAttribute('aria-hidden', true);
          scrollerInner.appendChild(duplicatedItem);
        });
      });
    }
  }, []);

  return (
    <div className="scroller" data-speed="slow">
        <div className="dark:text-[#E4E6EB] tag-list scroller__inner">
            <div className="Figma border-skill bg-change">
                <img src="image/figma-project.png" alt="" />
                <span>Figma</span>
            </div>
            <div className="Css border-skill bg-change">
                <img src="image/css-project.webp" alt="" />
                <span>CSS</span>
            </div>
            <div className="JavaScript border-skill bg-change">
                <img src="image/javascript-project.png" alt="" />
                <span>JavaScript</span>
            </div>
            <div className="Sass border-skill bg-change">
                <img src="image/sass-project.png" alt="" />
                <span>SASS</span>
            </div>
            <div className="ReactJs border-skill bg-change">
                <img src="image/reactjs-project.webp" alt="" />
                <span>React.js</span>
            </div>
            <div className="NodeJs border-skill bg-change">
                <img src="image/nodejs-project.png" alt="" />
                <span>Node.js</span>
            </div>
            <div className="Php border-skill bg-change">
                <img src="image/php-project.png" alt="" />
                <span>PHP</span>
            </div>
            <div className="Python border-skill bg-change">
                <img src="image/python-project.png" alt="" />
                <span>Python</span>
            </div>
            <div className="Git border-skill bg-change">
                <img src="image/git-project.png" alt="" />
                <span>Git</span>
            </div>
        </div>
    </div>
  );
}