import clsx from "clsx";
import { useState } from "react";

const ImageProject = ({ src, show }) => {
    const [isLoading, setLoading] = useState(true);
    return (
        <div
            className={clsx(
                `py-3 flex flex-shrink-0 ${show}`,
                isLoading ? 'animate-pulse' : ''
            )}
        >
            <img
                className={clsx(
                    "w-[500px] rounded-xl border border-[#e5e5e5] duration-700 ease-in-out",
                    isLoading ? 'scale-[1.02] blur-sm grayscale' : 'scale-100 blur-0 grayscale-0'
                )}
                src={src}
                alt={src}
                onLoad={() => setLoading(false)}
                loading="lazy" />
        </div>
    )
}
export default ImageProject