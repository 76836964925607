import React, { useEffect, useState } from 'react';
import Card from '../component/project/CardProject.jsx';
import { drawing } from '../component/home/drawing.js';
import HireMe from '../component/home/random.jsx'
import clsx from 'clsx';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
AOS.init();

export default function Home() {
    const [Render, setRender] = useState(true);
    useEffect(() => {
        drawing();
    }, []);

    return (
        <>
            <Helmet>
                <title>Wafi - Personal Website</title>
                <meta name="description" content="Personal website built with React.js, Firebase and Tailwind CSS." />
            </Helmet>
            <main className='relative font-poppins max-w-6xl mx-auto px-8 max-[768px]:mt-28 dark:text-[#E4E6EB] dark:fill-[#E4E6EB] max-[480px]:px-0' data-aos="fade-up">
                <div className='hidden lamp dark:block absolute z-0 w-full -top-32 blue__gradient' />
                <div className="flex relative flex-col text-center items-center">
                    <div
                        className={clsx(
                            'Caracter justify-center mt-8 max-[480px]:px-8',
                            Render ? 'animate-pulse' : ''
                        )}
                    >
                        <img
                            className={clsx(
                                'w-72 h-full',
                                Render ? 'bg-[#d4d4d4] rounded-full dark:bg-[#404040]' : ''
                            )}
                            src="image/me.webp"
                            alt="me"
                            loading='lazy'
                            onLoad={() => setRender(false)}
                        />
                    </div>
                    <div className="Text mt-[17rem] flex flex-col items-center absolute z-[2] max-[375px]:mt-[95%] max-[400px]:mt-[85%] max-[430px]:mt-[80%] max-[465px]:mt-[72%] max-[505px]:mt-[72%] max-[545px]:mt-[62%] max-[575px]:mt-[60%] max-[580px]:mt-[59%] max-[610px]:mt-[57%] max-[623px]:">
                        <h1 id='frontend' className='font-bold text-[60px] max-[375px]:text-[30px] max-[430px]:text-[35px] max-[623px]:text-[40px] max-[688px]:text-[53px] dark:hidden'>Frontend Developer,</h1>
                        <h1 id='darkfrontend' className='font-bold text-[60px] max-[375px]:text-[30px] max-[430px]:text-[35px] max-[623px]:text-[40px] max-[688px]:text-[53px] dark:block hidden'>Frontend Developer,</h1>
                        <div className='flex flex-col max-[480px]:px-8'>
                            <h2 className='font-bold text-5xl text-black max-[375px]:text-[25px] max-[430px]:text-[35px] max-[623px]:text-[40px] max-[688px]:text-[53px] dark:text-white'>UI/UX Designer</h2>
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-56 max-[375px]:w-32" fill="none" viewBox="0 0 346 24"><path id='path1' stroke="#5694ed" stroke-width="3" d="M1 18.61C111.19 1.714 209.119-2.292 294.787 6.59c2.103 1.37 1.447 2.211-1.968 2.525l-70.542 10.677c-1.016 1.455-.725 2.272.875 2.45 35.248-2.243 75.975-5.044 122.18-8.401" opacity="1" pathLength="1" stroke-dashoffset="0px" stroke-dasharray="1px 1px"></path></svg>
                            {/*ml-32*/}
                        </div>
                    </div>
                    <div className="gradient-box max-[768px]:mt-1 max-[480px]:px-8">
                        <div className='bg-gradient-to-b from-transparent to-[#fafafa] dark:to-[#121212]'></div>
                    </div>
                </div>
                <div className="ScrollDown flex items-center justify-center mb-52 mt-5 max-[768px]:mt-16 max-[480px]:px-8">
                    <svg xmlns="http://www.w3.org/2000/svg" className='w-[25px] max-[375px]:w-[22px]' viewBox="0 0 24 24"><path d="M11.975 22H12c3.859 0 7-3.14 7-7V9c0-3.841-3.127-6.974-6.981-7h-.06C8.119 2.022 5 5.157 5 9v6c0 3.86 3.129 7 6.975 7zM7 9a5.007 5.007 0 0 1 4.985-5C14.75 4.006 17 6.249 17 9v6c0 2.757-2.243 5-5 5h-.025C9.186 20 7 17.804 7 15V9z"></path><path d="M11 6h2v6h-2z"></path></svg>
                    <h1 className='max-[375px]:text-[12px]'>Scroll Down</h1>
                    <svg xmlns="http://www.w3.org/2000/svg" className='Angles-Down w-[25px] max-[375px]:w-[22px]' viewBox="0 0 24 24"><path d="m12 15.586-4.293-4.293-1.414 1.414L12 18.414l5.707-5.707-1.414-1.414z"></path><path d="m17.707 7.707-1.414-1.414L12 10.586 7.707 6.293 6.293 7.707 12 13.414z"></path></svg>
                </div>
                <div>
                    <div className="flex font-sora text-center items-center justify-between max-[480px]:px-8">
                        <div className="flex">
                            <svg className='w-11 max-[325px]:w-6 max-[365px]:w-7 max-[430px]:w-8 max-[540px]:w-9' stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M20.92 2.38A15.72 15.72 0 0 0 17.5 2a8.26 8.26 0 0 0-6 2.06Q9.89 5.67 8.31 7.27c-1.21-.13-4.08-.2-6 1.74a1 1 0 0 0 0 1.41l11.3 11.32a1 1 0 0 0 1.41 0c1.95-2 1.89-4.82 1.77-6l3.21-3.2c3.19-3.19 1.74-9.18 1.68-9.43a1 1 0 0 0-.76-.73zm-2.36 8.75L15 14.67a1 1 0 0 0-.27.9 6.81 6.81 0 0 1-.54 3.94L4.52 9.82a6.67 6.67 0 0 1 4-.5A1 1 0 0 0 9.39 9s1.4-1.45 3.51-3.56A6.61 6.61 0 0 1 17.5 4a14.51 14.51 0 0 1 2.33.2c.24 1.43.62 5.04-1.27 6.93z"></path><circle cx="15.73" cy="8.3" r="2"></circle><path d="M5 16c-2 1-2 5-2 5a7.81 7.81 0 0 0 5-2z"></path></svg>
                            <h1 className='font-bold text-4xl mt-1 ml-2 max-[325px]:text-base max-[365px]:text-[19px] max-[395px]:text-xl max-[430px]:text-2xl max-[540px]:text-[25px]'>Recent Project</h1>
                        </div>
                        <a href='/project' className="flex font-sora text-center justify-center gap-1 transition-all duration-300 mt-1 max-[375px]:hover:gap-2 hover:gap-3 group">
                            <h1 className='font-medium text-base text-[#525252] max-[325px]:text-[11px] max-[380px]:text-[12px] max-[430px]:text-[13px] max-[540px]:text-[14px] dark:text-[#a3a3a3] group-hover:dark:text-[#d4d4d4]'>View All</h1>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-6 fill-[#525252] max-[325px]:w-[16px] max-[380px]:w-[18px] max-[430px]:w-[20px] max-[540px]:w-[21px] dark:fill-[#a3a3a3] group-hover:dark:fill-[#d4d4d4]' viewBox="0 0 24 24"><path d="m11.293 17.293 1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z"></path></svg>
                        </a>
                    </div>
                    <div className="mt-9 gap-5 grid grid-cols-3 max-[768px]:mt-5 max-[480px]:px-8 max-[992px]:grid-cols-2 max-[768px]:grid-cols-2 max-[600px]:grid-cols-1">
                        <Card
                            link='project/warungdenwafi'
                            imgsrc='image/warungdenwafi/thumbnail.jpg'
                            hastag1='#warungdenwafi'
                            hastag2='#shop'
                            hastag3='#website'
                            title='UI - WarungDenWafi'
                            description='Design and live demo of WarungDenWafi food shop.'
                            date='9 June 2024'
                            imgsrctech1='image/figma-project.png'
                            imgsrctech2='image/vscode.png'
                            imgclass1='flex-shrink-0 w-6 h-6'
                            imgclass2='flex-shrink-0 w-6 h-6'
                            imgclass3='hidden'
                            imgclass4='hidden'
                            imgclass5='hidden'
                            classnextjslight='hidden'
                            classnextjsdark='hidden'
                        />
                        <Card
                            link='project/ui-flight-ticket-booking-app'
                            imgsrc='image/skylink/thumbnail.png'
                            hastag1='#flightticketbooking'
                            hastag2='#apps'
                            hastag3='#ui'
                            title='UI - Flight ticket booking app'
                            description='Design an online-based airplane ticket booking application.'
                            date='21 April 2024'
                            imgsrctech1='image/figma-project.png'
                            imgclass1='flex-shrink-0 w-6 h-6'
                            imgclass2='hidden'
                            imgclass3='hidden'
                            imgclass4='hidden'
                            imgclass5='hidden'
                            classnextjslight='hidden'
                            classnextjsdark='hidden'
                        />
                        <Card
                            link='https://github.com/muuwafi/confess'
                            imgsrc='image/Confess.webp'
                            hastag1='#frontend'
                            hastag2='#confess'
                            hastag3='#opensource'
                            title='Html Confess'
                            description='Confess html is open source'
                            date='28 January 2024'
                            imgsrctech1='image/html-project.png'
                            imgsrctech2='image/css-project.webp'
                            imgsrctech3='image/javascript-project.png'
                            imgclass1='flex-shrink-0 w-6 h-6'
                            imgclass2='flex-shrink-0 w-6 h-6'
                            imgclass3='flex-shrink-0 w-6 h-6'
                            imgclass4='hidden'
                            imgclass5='hidden'
                            classnextjslight='hidden'
                            classnextjsdark='hidden'
                        />
                    </div>
                </div>
                <div className="my-44 max-[480px]:px-8">
                    <div className="flex bg-[#525252] w-full rounded-2xl text-[#fafafa] py-14 px-16 items-center shadow-sm shadow-black max-[430px]:text-center max-[430px]:px-6 max-[725px]:flex-col dark:bg-[#262626] dark:border dark:border-[#404040]">
                        <div className="mr-16 max-[725px]:mr-0">
                            <h1 className='leading-[50px] pb-6 font-poppins font-bold text-4xl max-[375px]:text-sm max-[430px]:text-2xl'>I'm Ready To Be Hired For Your <span className='dark:text-[#2dd4bf]'>Next Project.</span></h1>
                            <p className='pb-6 max-[375px]:text-[12px] max-[430px]:text-[16px]'>I specialize in Front End Development and UI/UX Design, let's work together so that your project has relative quality and good results.</p>
                            <HireMe />
                        </div>
                        <img className='w-64 max-[725px]:mt-12' src="icon/hireme.svg" alt="hireme" loading='lazy' />
                    </div>
                </div>
            </main>
        </>
    )
};
