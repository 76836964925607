import React from "react";
import { useEffect, useState } from "react";
import Header from "../component/film/header";
import UpComing from "../component/film/UpComing"
import Trending from '../component/film/trending'
import Populer from '../component/film/populer'
import TopRated from '../component/film/TopRated'
import TypeTrending from '../component/film/TypeTrending'
import TypeTime from '../component/film/TypeTime'

export default function Film() {
    const [year, setYear] = useState('');
    useEffect(() => {
        const currentYear = new Date().getFullYear();
        setYear(currentYear.toString());
    }, []);
    const apikey = {
        method: 'GET',
        headers: {
            accept: 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_APIKEY_FILM}`
        }
    };
    return (
        <>
            <section className="bg-black h-fit w-screen font-poppins">
                <div className='clamp absolute z-0 w-full -top-40 red__gradient' />
                <div className="font-poppins max-w-6xl mx-auto px-8 max-[768px]:mt-28 text-[#E4E6EB] max-[480px]:px-0">
                    <Header />
                    <UpComing
                        apikey={apikey}
                    />
                    <div className="flex items-center justify-between mt-10">
                        <div className="flex gap-2">
                            <svg className='w-10 fill-[#121212] max-[375px]:w-9 max-[425px]:w-9 max-[600px]:w-10 dark:fill-white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="#fafafa" d="M384 160c-17.7 0-32-14.3-32-32s14.3-32 32-32H544c17.7 0 32 14.3 32 32V288c0 17.7-14.3 32-32 32s-32-14.3-32-32V205.3L342.6 374.6c-12.5 12.5-32.8 12.5-45.3 0L192 269.3 54.6 406.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160c12.5-12.5 32.8-12.5 45.3 0L320 306.7 466.7 160H384z" /></svg>
                            <h1 className='font-bold text-3xl mt-1 ml-2 max-[375px]:text-2xl max-[425px]:text-[22px] max-[600px]:text-[26px] text-[#E4E6EB]'>Trending</h1>
                        </div>
                        <div className="flex gap-4 items-center justify-center">
                            <TypeTrending />
                            <TypeTime/>
                        </div>
                    </div>
                    <div className="gap-5 flex mt-5 overflow-x-auto py-3">
                        <button className="bg-[#171717] py-3 px-6 rounded-full font-medium text-[#fafafa] text-sm flex-shrink-0">Drama</button>
                        <button className="bg-[#171717] py-3 px-6 rounded-full font-medium text-[#fafafa] text-sm flex-shrink-0">Comedy</button>
                        <button className="bg-[#171717] py-3 px-6 rounded-full font-medium text-[#fafafa] text-sm flex-shrink-0">Action</button>
                        <button className="bg-[#171717] py-3 px-6 rounded-full font-medium text-[#fafafa] text-sm flex-shrink-0">Adventure</button>
                        <button className="bg-[#171717] py-3 px-6 rounded-full font-medium text-[#fafafa] text-sm flex-shrink-0">Horror</button>
                        <button className="bg-[#171717] py-3 px-6 rounded-full font-medium text-[#fafafa] text-sm flex-shrink-0">Fantasy</button>
                        <button className="bg-[#171717] py-3 px-6 rounded-full font-medium text-[#fafafa] text-sm flex-shrink-0">Sci-Fi</button>
                        <button className="bg-[#171717] py-3 px-6 rounded-full font-medium text-[#fafafa] text-sm flex-shrink-0">Romance</button>
                        <button className="bg-[#171717] py-3 px-6 rounded-full font-medium text-[#fafafa] text-sm flex-shrink-0">Thriller</button>
                        <button className="bg-[#171717] py-3 px-6 rounded-full font-medium text-[#fafafa] text-sm flex-shrink-0">Mysteri</button>
                        <button className="bg-[#171717] py-3 px-6 rounded-full font-medium text-[#fafafa] text-sm flex-shrink-0">Animation</button>
                        <button className="bg-[#171717] py-3 px-6 rounded-full font-medium text-[#fafafa] text-sm flex-shrink-0">Musical</button>
                    </div>
                    <Trending
                        apikey={apikey}
                    />
                    <div className="flex gap-2 mt-12">
                        <svg className='w-10  max-[375px]:w-9 max-[425px]:w-9 max-[600px]:w-10 fill-white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#fafafa" d="M159.3 5.4c7.8-7.3 19.9-7.2 27.7 .1c27.6 25.9 53.5 53.8 77.7 84c11-14.4 23.5-30.1 37-42.9c7.9-7.4 20.1-7.4 28 .1c34.6 33 63.9 76.6 84.5 118c20.3 40.8 33.8 82.5 33.8 111.9C448 404.2 348.2 512 224 512C98.4 512 0 404.1 0 276.5c0-38.4 17.8-85.3 45.4-131.7C73.3 97.7 112.7 48.6 159.3 5.4zM225.7 416c25.3 0 47.7-7 68.8-21c42.1-29.4 53.4-88.2 28.1-134.4c-4.5-9-16-9.6-22.5-2l-25.2 29.3c-6.6 7.6-18.5 7.4-24.7-.5c-16.5-21-46-58.5-62.8-79.8c-6.3-8-18.3-8.1-24.7-.1c-33.8 42.5-50.8 69.3-50.8 99.4C112 375.4 162.6 416 225.7 416z" /></svg>
                        <h1 className='font-bold text-3xl mt-1 ml-2 max-[375px]:text-2xl max-[425px]:text-[22px] max-[600px]:text-[26px] text-[#E4E6EB]'>Populer</h1>
                    </div>
                    <Populer
                        apikey={apikey}
                    />
                    <div className="flex gap-2 mt-12">
                        <svg className='w-10  max-[375px]:w-9 max-[425px]:w-9 max-[600px]:w-10 fill-white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="#fafafa" d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" /></svg>
                        <h1 className='font-bold text-3xl mt-1 ml-2 max-[375px]:text-2xl max-[425px]:text-[22px] max-[600px]:text-[26px] text-[#E4E6EB]'>Top Rated</h1>
                    </div>
                    <TopRated
                        apikey={apikey}
                    />
                </div>
                <div className="mt-20">
                    <hr className='border-[#404040]' />
                    <div className="font-poppins text-sm my-3 flex justify-center max-[375px]:text-[11px] text-[#d4d4d4]">
                        <h1>&copy;{year} Muhammad Wafi | All rights reserved</h1>
                    </div>
                </div>
            </section>
        </>
    )
}