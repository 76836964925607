import React, { useState } from 'react';
import { app } from '../../api/firebase'
import { getFirestore, collection, addDoc, serverTimestamp, } from 'firebase/firestore'
import clsx from 'clsx';
import { useParams } from 'react-router-dom';

export default function Modal({ user, isOpen, onClose, handleGoogleLogout }) {
    const [rating, setRating] = useState(0);
    const [newMessage, setNewMessage] = useState("");
    const db = getFirestore(app)
    const { projectId } = useParams();
    const [Render, setRender] = useState(true);

    const handleRatingChange = (value) => {
        setRating(value);
    };
    const author = process.env.REACT_APP_AUTHOR;
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();

    const messageDate = `${day}/${month}/${year}, ${hours}:${minutes}`;

    const handleSubmit = async () => {
        if (!newMessage.trim()) {
            alert('Please fill in the review field!');
            return;
        }
        let dataToSend = {
            email: user.email,
            uid: user.uid,
            photoURL: user.photoURL,
            displayName: user.displayName,
            text: newMessage,
            date: messageDate,
            rating: rating,
            timestamp: serverTimestamp()
        }
        await addDoc(collection(db, projectId), dataToSend);
        setNewMessage('')
        setRating(null)
        window.location.reload();
    };

    return (
        <>
            {isOpen && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen">
                        <div
                            className="fixed inset-0 bg-black bg-opacity-50 transition-opacity dark:bg-[#262626] dark:bg-opacity-50 dark:transition-opacity"
                            onClick={onClose}
                        ></div>
                        <div className="relative bg-white px-5 w-1/2 mx-auto rounded-lg max-[769px]:w-[90vw] dark:bg-black">
                            <div className="flex my-3 justify-between">
                                <div className='flex gap-3 items-center'>
                                    <button
                                        onClick={onClose}
                                        className=""
                                    >
                                        <svg className="w-4 fill-[#121212] dark:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                                    </button>
                                    <h1 className="text-[#121212] text-[13px] font-normal dark:text-white">Give a Review</h1>
                                </div>
                                <div>
                                    <button className='flex items-center gap-2 text-[#ef4444] text-[13px]' onClick={() => handleGoogleLogout()}>
                                        <p>
                                            <svg className='w-4' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#ef4444" d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z" /></svg>
                                        </p>
                                        Sign Out
                                    </button>
                                </div>
                            </div>
                            <hr className='border-[#d1d5db] dark:border-[#404040]' />
                            <div
                                className={clsx(
                                    "py-5 flex flex-col items-center space-y-2 justify-center",
                                    Render ? 'animate-pulse' : '',
                                )}
                            >
                                <img
                                    className={clsx(
                                        "w-24 rounded-full",
                                        Render ? 'scale-[1.02] blur-sm grayscale' : 'scale-100 blur-0 grayscale-0'
                                    )}
                                    src={user.photoURL}
                                    alt="avatar"
                                    onLoad={() => setRender(false)}
                                    loading='lazy' />
                            </div>
                            <div className="flex flex-col items-center pb-5">
                                <div className='flex gap-[2px]'>
                                    <h1 className="font-medium text-black dark:text-white">{user.displayName}</h1>
                                    {user.email === author ? (
                                        <img alt='verified' className="w-[14px] mt-1 h-[14px] ml-1" src="/image/verified.png" />
                                    ) : (
                                        <span className='hidden'></span>
                                    )}
                                </div>
                                <p className="text-[13px] text-[#737373]">{day}/{month}/{year}</p>
                            </div>
                            <div className="flex justify-center">
                                <div className="rating">
                                    {[...Array(5)].map((_, index) => (
                                        <React.Fragment key={index}>
                                            <input
                                                className='hidden'
                                                value={5 - index}
                                                name="rate"
                                                id={`star${5 - index}`}
                                                type="radio"
                                                checked={rating === 5 - index}
                                                onChange={() => handleRatingChange(5 - index)}
                                            />
                                            <label title="text" htmlFor={`star${5 - index}`}></label>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                            <textarea
                                onChange={e => setNewMessage(e.target.value)}
                                placeholder="Review*"
                                value={newMessage}
                                className="w-full h-32 px-4 py-2 my-2 rounded-md border border-[#ccc] bg-border-mode dark:text-[#fafafa] focus:outline-none focus:ring-0 focus:border-[#ccc] dark:focus:border-none"
                            />
                            <button
                                className="send gap-2 mb-3 bg-[#525252] text-[#fafafa] flex ml-auto rounded-xl overflow-hidden transition-all duration-200 cursor-pointer py-3 px-5 dark:bg-[#262626]"
                                onClick={handleSubmit}
                            >
                                <div class="svg-wrapper-1">
                                    <div class="svg-wrapper">
                                        <svg
                                            className="w-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                        >
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path
                                                fill="currentColor"
                                                d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                                            ></path>
                                        </svg>
                                    </div>
                                </div>
                                <span className="block text-sm transition-all duration-300 ease-in-out">Send</span>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};