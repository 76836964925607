import Groq from "groq-sdk";

const groq = new Groq({
    apiKey: process.env.REACT_APP_APIKEY_GROQ_AI,
    dangerouslyAllowBrowser: true
});

export const requestGroqAI = async (content) => {
    const reply = await groq.chat.completions.create({
        messages: [{
            role: "user",
            content,
        },],
        model: "llama3-8b-8192"
    })
    return reply.choices[0].message.content
}