import React from "react";
import { useParams } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from "../component/footer/footer";
import DetailProject from "../component/project/DetailProject/DetailProject";
AOS.init();

const ProjectDetaill = () => {
    const { projectId } = useParams();
    const handleRefresh = () => {
        window.location.reload();
    }
    let projectContent;
    switch (projectId) {
        case 'ui-flight-ticket-booking-app':
            projectContent = (
                <>
                    <DetailProject
                        linkthumb="https://raw.githubusercontent.com/muuwafi/detailprojectthumb/main/flighttickebookingapps.png"
                        title="UI - Flight ticket booking app"
                        content="Design an online-based airplane ticket booking application."
                        datepublish="21/04/2004"
                        srctt1="/image/figma-project.png" srctt2="" srctt3="" srctt4="" srctt5=""
                        namett1="figma" namett2="" namett3="" namett4="" namett5=""
                        showtt1="" showtt2="hidden" showtt3="hidden" showtt4="hidden" showtt5="hidden"
                        srcip1="/image/skylink/1.png" srcip2="/image/skylink/2.png" srcip3="/image/skylink/3.png" srcip4="/image/skylink/4.png" srcip5="/image/skylink/5.png" srcip6="/image/skylink/6.png" srcip7="/image/skylink/7.png" srcip8="/image/skylink/8.png" srcip9="/image/skylink/9.png" srcip10="/image/skylink/10.png" srcip11="/image/skylink/update.png" srcip12="" srcip13="" srcip14="" srcip15=""
                        showip1="" showip2="" showip3="" showip4="" showip5="" showip6="" showip7="" showip8="" showip9="" showip10="" showip11="" showip12="hidden" showip13="hidden" showip14="hidden" showip15="hidden"
                        intro1="SkyLink comes with the goal of simplifying and enhancing your airline ticket booking experience. With a user-friendly and intuitive design, we ensure that every step in the booking process is made easy and convenient. From choosing a travel route to payment, we have carefully designed every feature to ensure your convenience." intro2="With SkyLink, navigation becomes easier and more efficient. The clean interface and well-organized layout ensure that you can quickly find the information you need. No longer confused by changing flight schedules or ticket prices, SkyLink helps you explore your travel options quickly and precisely." intro3="Enjoy a smooth and stress-free journey with SkyLink. With our top-notch app design, you can focus on the things that matter during your trip. From booking to trip completion, SkyLink is here to support every step in your adventure."
                        asset="Pinterest, Storyset, Boxicons, Hugeicons"
                        iconuc1="🚀" iconuc2="🏠" iconuc3="🎫" iconuc4="👤" iconuc5="🔔" iconuc6="📍" iconuc7="🔎" iconuc8="🕒" iconuc9="📆" iconuc10="⚙️"
                        ctg1="Onboarding" ctg2="Home" ctg3="Booking Ticket" ctg4="Profile" ctg5="Notifications" ctg6="Address" ctg7="Search" ctg8="History" ctg9="Calender" ctg10="Account & Setting"
                        showuc1="" showuc2="" showuc3="" showuc4="" showuc5="" showuc6="" showuc7="" showuc8="" showuc9="" showuc10=""
                        tag1="#flightticketbooking" tag2="#apps" tag3="#ui"
                        linkto1="/download/ui-flightbookingticketapp.zip" linkto2="" linkto3=""
                        fileName1="ui-flightbookingticketapp"
                        active1="" active2="hidden" active3="hidden"
                    />
                </>
            );
            break;
        case 'warungdenwafi':
            projectContent = (
                <>
                    <DetailProject
                        linkthumb="https://raw.githubusercontent.com/muuwafi/detailprojectthumb/main/warungdenwafi.png"
                        title="UI - WarungDenWafi"
                        content="WarungDenWafi serves authentic Balinese flavors with fresh and quality ingredients."
                        datepublish="09/06/2024"
                        srctt1="/image/figma-project.png" srctt2="/image/vscode.png" srctt3="" srctt4="" srctt5=""
                        namett1="figma" namett2="vscode" namett3="" namett4="" namett5=""
                        showtt1="" showtt2="" showtt3="hidden" showtt4="hidden" showtt5="hidden"
                        srcip1="/image/warungdenwafi/ip-warungdenwafi-1.jpg" srcip2="/image/warungdenwafi/ip-warungdenwafi-2.jpg" srcip3="/image/warungdenwafi/ip-warungdenwafi-3.jpg" srcip4="" srcip5="" srcip6="" srcip7="" srcip8="" srcip9="" srcip10="" srcip11="" srcip12="" srcip13="" srcip14="" srcip15=""
                        showip1="" showip2="" showip3="" showip4="hidden" showip5="hidden" showip6="hidden" showip7="hidden" showip8="hidden" showip9="hidden" showip10="hidden" showip11="hidden" showip12="hidden" showip13="hidden" showip14="hidden" showip15="hidden"
                        intro1='The user interface (UI) of WarungDenWafi has a clean and modern design with a dominant green color that reflects the freshness and deliciousness of the dishes offered. The top of the page features a simple navigation with four main menus: Home, About, Menu, and Review, as well as a Sign In button in the top right corner. Underneath are appetizing food images and a slogan inviting diners to enjoy a seamless culinary experience, accompanied by an eye-catching "Order Now" button.' intro2='The center of the page displays important information such as operating hours, address, and phone number, followed by a brief description of WarungDenWafi and their mission of serving authentic Indonesian dishes. There is also a "Read More" button that allows users to get more information about the restaurant. In this section, users can view three popular menus complete with images and prices, which makes it easy for diners to see the food options available.' intro3='At the bottom, there are customer reviews that give positive testimonials about their experience at WarungDenWafi. This is followed by a selection of reasons why customers should choose this restaurant, namely food quality and fast delivery. The footer of the page includes a brief description of the restaurant and links to social media, providing an informative finishing touch and connecting users with the WarungDenWafi online community. Overall, this UI design is effective in conveying important information in an attractive and easy-to-navigate way.'
                        asset="Pinterest, Hugeicons"
                        iconuc1="" iconuc2="🏠" iconuc3="" iconuc4="👥" iconuc5="🥗" iconuc6="⭐" iconuc7="" iconuc8="" iconuc9="" iconuc10=""
                        ctg1="" ctg2="Home" ctg3="" ctg4="About" ctg5="Menu" ctg6="Review" ctg7="" ctg8="" ctg9="" ctg10=""
                        showuc1="hidden" showuc2="" showuc3="hidden" showuc4="" showuc5="" showuc6="" showuc7="hidden" showuc8="hidden" showuc9="hidden" showuc10="hidden"
                        tag1="#warungdenwafi" tag2="#shop" tag3="#website"
                        linkto1="/download/ui-warungdenwafi.zip" linkto2="https://github.com/muuwafi/warungdenwafi" linkto3="preview"
                        fileName1="ui-warungdenwafi"
                        active1="" active2="" active3=""
                    />
                </>
            );
            break;
        default:
            projectContent =
                <>
                    <div className="flex justify-center py-12 px-8 max-w-6xl mx-auto">
                        <img className="w-96" src="/ilustrasi/notfound.svg" alt="404" />
                    </div>
                </>
    }
    return (
        <>
            <main className="font-poppins" data-aos='fade-up'>
                {projectContent}
            </main>
            <Footer />
        </>
    );
};
export default ProjectDetaill;