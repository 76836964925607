import Slider from "react-slick";
import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function ThumbnailMovieUpcoming({ apikey }) {
    const [movieUpcoming, setMovieUpcoming] = useState([]);
    useEffect(() => {
        fetch('https://api.themoviedb.org/3/movie/upcoming?language=en-US&page=1', apikey)
            .then(response => response.json())
            .then(response => setMovieUpcoming(response.results))
            .catch(err => console.error(err));
    }, [])
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <div className="flex flex-col justify-center mt-32 space-y-10">
            <div className="w-full h-96 rounded-2xl overflow-hidden shadow-md">
                <Slider {...settings}>
                    {movieUpcoming.map((res, index) => (
                        <div className="relative">
                            <img className="w-full h-full object-cover" src={`https://image.tmdb.org/t/p/w500${res.backdrop_path}`} alt={`image-${index + 1}`} />
                            <div className="absolute inset-0 bg-gradient-to-l from-transparent to-black"></div>
                            <div className="absolute inset-0 flex flex-col my-10 pl-10 space-y-4 text-balance mt-56">
                                <p className="text-white text-xs">Release : {res.release_date}</p>
                                <h1 className="text-4xl font-semibold text-white">{res.original_title}</h1>
                                <p className=" text-white text-xs">{res.overview}</p>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}