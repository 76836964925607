import React from 'react';
import Card from '../component/project/CardProject'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
AOS.init();

export default function RecentProject(props) {

  const projectDescription = props.h1 || "Project";
  const descriptionProject = props.desc || 'The following are some of the projects I have worked on, both private and open source.'
  const pembatas = props.pembatas || <hr className='border-[#d1d5db] dark:border-[#404040]' />

  return (
    <>
      <Helmet>
        <title>Project - Wafi</title>
        <meta name="description" content="The following are some of the projects I have worked on, both private and open source." />
      </Helmet>
      <main className='relative font-sora max-w-6xl mx-auto px-8 mt-8 max-[768px]:mt-28 dark:text-[#E4E6EB] dark:fill-[#E4E6EB]' data-aos='fade-up'>
        <div className='hidden plamp dark:block absolute z-0 w-full -top-40 blue__gradient' />
        <div className="relative">
          <div className="">
            <div className="FlexProject flex items-center">
              <svg className='w-11 text-[#121212] max-[375px]:w-9 max-[425px]:w-9 max-[600px]:w-10 dark:text-[#E4E6EB]' stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M20.92 2.38A15.72 15.72 0 0 0 17.5 2a8.26 8.26 0 0 0-6 2.06Q9.89 5.67 8.31 7.27c-1.21-.13-4.08-.2-6 1.74a1 1 0 0 0 0 1.41l11.3 11.32a1 1 0 0 0 1.41 0c1.95-2 1.89-4.82 1.77-6l3.21-3.2c3.19-3.19 1.74-9.18 1.68-9.43a1 1 0 0 0-.76-.73zm-2.36 8.75L15 14.67a1 1 0 0 0-.27.9 6.81 6.81 0 0 1-.54 3.94L4.52 9.82a6.67 6.67 0 0 1 4-.5A1 1 0 0 0 9.39 9s1.4-1.45 3.51-3.56A6.61 6.61 0 0 1 17.5 4a14.51 14.51 0 0 1 2.33.2c.24 1.43.62 5.04-1.27 6.93z"></path><circle cx="15.73" cy="8.3" r="2"></circle><path d="M5 16c-2 1-2 5-2 5a7.81 7.81 0 0 0 5-2z"></path></svg>
              <h1 className='font-bold text-[#121212] text-4xl mt-1 ml-2 max-[375px]:text-2xl max-[425px]:text-[22px] max-[600px]:text-[26px] dark:text-[#E4E6EB]'>{projectDescription}</h1>
            </div>
            <p className='mt-3 text-[17px] mb-2 text-[#525252] max-[375px]:text-[15px] max-[425px]:text-[16px] max-[600px]:text-[17px] dark:text-[#a3a3a3]'>{descriptionProject}</p>
            {pembatas}
          </div>
          <div className="CardProject mt-9 gap-5 grid grid-cols-3 max-[992px]:grid-cols-2 max-[768px]:grid-cols-2 max-[600px]:grid-cols-1">
            <Card
              link='warungdenwafi'
              imgsrc='image/warungdenwafi/thumbnail.jpg'
              hastag1='#warungdenwafi'
              hastag2='#shop'
              hastag3='#website'
              title='UI - WarungDenWafi'
              description='Design and live demo of WarungDenWafi food shop.'
              date='9 June 2024'
              imgsrctech1='image/figma-project.png'
              imgsrctech2='image/vscode.png'
              imgclass1='flex-shrink-0 w-6 h-6'
              imgclass2='flex-shrink-0 w-6 h-6'
              imgclass3='hidden'
              imgclass4='hidden'
              imgclass5='hidden'
              classnextjslight='hidden'
              classnextjsdark='hidden'
            />
            <Card
              link='ui-flight-ticket-booking-app'
              imgsrc='image/skylink/thumbnail.png'
              hastag1='#flightticketbooking'
              hastag2='#apps'
              hastag3='#ui'
              title='UI - Flight ticket booking app'
              description='Design an online-based airplane ticket booking application.'
              date='21 April 2024'
              imgsrctech1='image/figma-project.png'
              imgclass1='flex-shrink-0 w-6 h-6'
              imgclass2='hidden'
              imgclass3='hidden'
              imgclass4='hidden'
              imgclass5='hidden'
              classnextjslight='hidden'
              classnextjsdark='hidden'
            />
            <Card
              link='https://github.com/muuwafi/confess'
              imgsrc='image/Confess.webp'
              hastag1='#frontend'
              hastag2='#confess'
              hastag3='#opensource'
              title='Html Confess'
              description='Confess html is open source'
              date='28 January 2024'
              imgsrctech1='image/html-project.png'
              imgsrctech2='image/css-project.webp'
              imgsrctech3='image/javascript-project.png'
              imgclass1='flex-shrink-0 w-6 h-6'
              imgclass2='flex-shrink-0 w-6 h-6'
              imgclass3='flex-shrink-0 w-6 h-6'
              imgclass4='hidden'
              imgclass5='hidden'
              classnextjslight='hidden'
              classnextjsdark='hidden'
            />
            <Card
              link='https://muuwafi.site'
              imgsrc='image/PersonalWebsite.webp'
              hastag1='#frontend'
              hastag2='#personal'
              hastag3='#website'
              title='muuwafi.site'
              description='Personal website built with React.js, Firebase and Tailwind CSS.'
              date='5 December 2022'
              imgsrctech1='image/reactjs-project.webp'
              imgsrctech2='image/javascript-project.png'
              nextjslight='image/nextjs-dark.webp'
              nextjsdark='image/nextjs-light.png'
              imgsrctech3='image/tailwind-project.png'
              imgclass1='flex-shrink-0 w-6 h-6'
              imgclass2='flex-shrink-0 w-6 h-6'
              imgclass3='flex-shrink-0 w-6 h-6'
              imgclass4='hidden'
              imgclass5='hidden'
              classnextjslight='hidden dark:block'
              classnextjsdark='dark:hidden'
            />
            <Card
              link='https://s.id/1ZEGu'
              imgsrc='image/HappyNewYear.webp'
              hastag1='#frontend'
              hastag2='#newyear'
              hastag3='#opensource'
              title='Happy New Year'
              description='New year html is open source'
              date='31 December 2023'
              imgsrctech1='image/html-project.png'
              imgsrctech2='image/css-project.webp'
              imgsrctech3='image/javascript-project.png'
              imgclass1='flex-shrink-0 w-6 h-6'
              imgclass2='flex-shrink-0 w-6 h-6'
              imgclass3='flex-shrink-0 w-6 h-6'
              imgclass4='hidden'
              imgclass5='hidden'
              classnextjslight='hidden'
              classnextjsdark='hidden'
            />
            <Card
              link='https://s.id/1ZBPI'
              imgsrc='image/LandingPage.webp'
              hastag1='#frontend'
              hastag2='#javascript'
              hastag3='#personal'
              title='Landing Page'
              description='Personal landing page built with javascript'
              date='26 September 2023'
              imgsrctech1='image/html-project.png'
              imgsrctech2='image/css-project.webp'
              imgsrctech3='image/javascript-project.png'
              imgclass1='flex-shrink-0 w-6 h-6'
              imgclass2='flex-shrink-0 w-6 h-6'
              imgclass3='flex-shrink-0 w-6 h-6'
              imgclass4='hidden'
              imgclass5='hidden'
              classnextjslight='hidden'
              classnextjsdark='hidden'
            />
            <Card
              link='https://s.id/1ZBPc'
              imgsrc='image/SocialMediaUpdate.png'
              hastag1='#frontend'
              hastag2='#update'
              hastag3='#personal'
              title='Social Media Link'
              description='Updated UI from firstproject'
              date='5 December 2022'
              imgsrctech1='image/html-project.png'
              imgsrctech2='image/css-project.webp'
              imgclass1='flex-shrink-0 w-6 h-6'
              imgclass2='flex-shrink-0 w-6 h-6'
              imgclass3='hidden'
              imgclass4='hidden'
              imgclass5='hidden'
              classnextjslight='hidden'
              classnextjsdark='hidden'
            />
            <Card
              link='https://s.id/1ZBPE'
              imgsrc='image/FirstProject.png'
              hastag1='#frontend'
              hastag2='#firstproject'
              hastag3='#personal'
              title='Social Media Link'
              description='The first social media link website was created with html and css'
              date='23 September 2022'
              imgsrctech1='image/html-project.png'
              imgsrctech2='image/css-project.webp'
              imgclass1='flex-shrink-0 w-6 h-6'
              imgclass2='flex-shrink-0 w-6 h-6'
              imgclass3='hidden'
              imgclass4='hidden'
              imgclass5='hidden'
              classnextjslight='hidden'
              classnextjsdark='hidden'
            />
          </div>
        </div>
      </main>
    </>
  );
};
