import React, { useState } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from "react-helmet";
AOS.init();

export default function About() {
    const [nama, setNama] = useState('');
    const [email, setEmail] = useState('');
    const [pesan, setPesan] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const regex = /^[a-zA-Z\s]*$/;

        if (!nama.trim() || !email.trim() || !pesan.trim()) {
            alert('Please fill out all forms!');
            return;
        }

        if (!regex.test(nama)) {
            alert('Name should only contain letters!');
            setNama('');
            return;
        }

        const message = `Pesan%20Dari%20${nama}%0A%0AEmail%20%3A%0A${email}%0AIsi%20%3A%0A${pesan}`;
        const url = process.env.REACT_APP_URL_CONTACT + `/sendMessage?chat_id=-1002000274091&text=${message}&page_mode=html`;

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ nama, email, pesan }),
            });

            if (response.ok) {
                alert('Message sent successfully!');
            }
        } catch (error) {
            console.error('An error occurred, try again!', error);
        }

        setNama('');
        setEmail('');
        setPesan('');
    };

    return (
        <>
            <Helmet>
                <title>Contact - Wafi</title>
                <meta name="description" content="Feel free to contact me, I will reply as soon as possible and let's discuss how we can work together." />
            </Helmet>
            <div className="max-w-6xl mx-auto px-8 relative font-sora mt-8 rounded-lg max-[768px]:mt-28" data-aos='fade-up'>
                <div className='hidden clamp dark:block absolute z-0 w-full -top-40 blue__gradient' />
                <div>
                    <div className="flex items-center text-3xl font-bold dark:text-[#E4E6EB]">
                        <h1 className="">Contact </h1>
                        <img className="w-8 ml-3" src="image/gmail.png" alt="gmail" loading="lazy" />
                        <h1 className="">e</h1>
                    </div>
                    <p className='mt-3 text-[17px] text-[#525252] mb-2 textparagraf-responsive dark:text-[#a3a3a3]'>Feel free to contact me, I will reply as soon as possible and let's discuss how we can work together.</p>
                </div>
                <hr className="border-[#d1d5db] dark:border-[#404040]"></hr>
                <div className="flex justify-between max-[768px]:flex-col mt-10">
                    <div className="">
                        <h1 className="text-2xl font-medium mb-4 dark:text-[#E4E6EB] max-[768px]:text-xl">Find me on social media</h1>
                        <div className="flex flex-col mr-12 text-[#fafafa] max-[768px]:mr-0">
                            <div className="flex mr-5 mb-4 max-[768px]:mr-0">
                                <a href="https://www.instagram.com/muuwafi/" className="border-social-media mr-5 max-[375px]:mr-2 bg-[#c32aa3]">
                                    <img src="icon/instagram.svg" alt="" className="w-6 h-6 mr-2" />
                                    <h1 className="font-semibold text-sm">Instagram</h1>
                                </a>
                                <a href="https://www.linkedin.com/in/muuwafi/" className="border-social-media bg-[#0a66c2]">
                                    <img src="icon/linkedin.svg" alt="" className="w-6 h-6 mr-2" />
                                    <h1 className="font-semibold text-sm">Linkedin</h1>
                                </a>
                            </div>
                            <div className="flex mr-5 mb-4 max-[768px]:mr-0">
                                <a href="https://www.behance.net/muuwafi" className="border-social-media mr-5 max-[375px]:mr-2 bg-[#1769ff]">
                                    <img src="icon/behance.svg" alt="" className="w-6 h-6 mr-2" />
                                    <h1 className="font-semibold text-sm">Behance</h1>
                                </a>
                                <a href="https://github.com/muuwafi" className="border-social-media bg-[#333]">
                                    <img src="icon/github.svg" alt="" className="w-6 h-6 mr-2" />
                                    <h1 className="font-semibold text-sm">Github</h1>
                                </a>
                            </div>
                            <div className=" flex">
                                <a href="mailto:muhammadwafi29807@gmail.com" className="h-[43px] w-[380px] flex items-center justify-center rounded-lg hover:scale-105 transition duration-300 bg-[#22c55e]">
                                    <img src="icon/email.svg" alt="" className="w-6 h-6 mr-2" />
                                    <h1 className="font-semibold text-sm">Email</h1>
                                </a>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit} className="flex flex-col">
                        <hr className="hidden border-[#d1d5db] dark:border-[#404040] max-[768px]:mt-10 max-[768px]:block"></hr>
                        <h1 className="text-2xl font-medium mb-4 dark:text-[#E4E6EB] max-[768px]:text-xl max-[768px]:mt-10">Or send me a message</h1>
                        <div className="flex mb-4">
                            <input
                                placeholder="Name*"
                                className="w-1/2 px-4 py-2  border border-[#ccc] rounded-md bg-border-mode dark:text-[#fafafa] focus:outline-none focus:ring-0 focus:border-[#ccc] dark:focus:border-none"
                                type="text"
                                id="nama"
                                value={nama}
                                onChange={(e) => setNama(e.target.value)}
                            />
                            <input
                                placeholder="Email*"
                                className="w-1/2 ml-4 px-4 py-2 border border-[#ccc] rounded-md bg-border-mode dark:text-[#fafafa] focus:outline-none focus:ring-0 focus:border-[#ccc] dark:focus:border-none"
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <textarea
                            placeholder="Message*"
                            className="w-full h-32 px-4 py-2 rounded-md border border-[#ccc] bg-border-mode dark:text-[#fafafa] focus:outline-none focus:ring-0 focus:border-[#ccc] dark:focus:border-none"
                            id="pesan"
                            value={pesan}
                            onChange={(e) => setPesan(e.target.value)}
                        />
                        <button
                            type="submit"
                            className="w-1/2 mt-4 px-6 py-3 bg-[#525252] text-white text-[15px] rounded-md hover:scale-[1.03] transition duration-300 dark:hover:bg-[#fafafa] dark:hover:text-[#121212]"
                        >
                            Send Message
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
}
