import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import AOS from 'aos';
import { useRef } from "react";
AOS.init();

export default function WarungDenWafi() {
    const aboutRef = useRef(null);
    const scrollToAbout = () => {
        aboutRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    const menuRef = useRef(null);
    const scrollToMenu = () => {
        menuRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    const reviewRef = useRef(null);
    const scrollToReview = () => {
        reviewRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
            <Helmet>
                <title>Preview - WarungDenWafi</title>
                <meta name="description" content="Preview of WarungDenWafi UI" />
            </Helmet>
            <div className="font-jakartasans max-w-7xl mx-auto w-screen max-[1120px]:hidden dark:bg-[#fafafa]" data-aos='fade-up'>
                <header className="bg-[#397E08] px-16 py-4 flex justify-between items-center">
                    <h1 className="text-white text-2xl font-bold">WarungDenWafi</h1>
                    <nav className="flex items-center gap-8 text-white">
                        <button>Home</button>
                        <Link onClick={scrollToAbout}>About</Link>
                        <Link onClick={scrollToMenu}>Menu</Link>
                        <Link onClick={scrollToReview}>Review</Link>
                    </nav>
                    <button className="bg-white text-[#397E08] font-semibold px-6 py-2 rounded">Sign In</button>
                </header>

                <section className="relative bg-[#397E08] text-white px-16 py-5 rounded-br-full">
                    <div className="flex items-center justify-between">
                        <div className="pr-44">
                            <h2 className="text-5xl font-bold mb-4">Experience Limitless Culinary Delight!</h2>
                            <p className="mb-8 text-lg">
                                Discover an indulgent fusion of flavors with special dishes from our talented chefs.
                                Make your reservation soon for an unforgettable dining experience.
                            </p>
                            <div className="flex items-center gap-9">
                                <button className="bg-white text-[#397E08] px-6 py-3 rounded-full font-semibold">Order Now!</button>
                                <div className="flex items-center">
                                    <div className="flex -space-x-5">
                                        <img className="w-12 rounded-full border-2 border-[#397E08]" src="/image/avatar.webp" alt="User 1" />
                                        <img className="w-12 rounded-full border-2 border-[#397E08]" src="/image/avatar.webp" alt="User 2" />
                                        <img className="w-12 rounded-full border-2 border-[#397E08]" src="/image/avatar.webp" alt="User 3" />
                                        <img className="w-12 rounded-full border-2 border-[#397E08]" src="/image/avatar.webp" alt="User 3" />
                                    </div>
                                    <div className="ml-4 text-sm">
                                        <div>
                                            <span className="text-[#facc15] text-2xl">★★★★</span>
                                            <span className="text-white text-2xl">★</span>
                                        </div>
                                        <span className="block">400+ Comment</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img className="w-96" src="/image/warungdenwafi/hero-sec-warung.png" alt="Food" />
                    </div>
                </section>

                <section className="px-20 py-36">
                    <div className="flex justify-around">
                        <div className="bg-white flex flex-col items-center w-80 py-8 rounded-3xl shadow-md text-center space-y-3">
                            <img className="w-8" src="/icon/clock-01.svg" alt="icon working" />
                            <div>
                                <h3 className="text-lg font-bold">10.00 am - 07.00 pm</h3>
                                <p className="text-base text-[#A3A3A3] font-medium">Working Hours</p>
                            </div>
                        </div>
                        <div className="bg-white flex flex-col items-center w-80 py-8 rounded-3xl shadow-md text-center space-y-3">
                            <img className="w-8" src="/icon/location-01.svg" alt="icon address" />
                            <div>
                                <h3 className="text-lg font-bold">Wyoming 82201</h3>
                                <p className="text-base text-[#A3A3A3] font-medium">Address</p>
                            </div>
                        </div>
                        <div className="bg-white flex flex-col items-center w-80 py-8 rounded-3xl shadow-md text-center space-y-3">
                            <img className="w-8" src="/icon/telephone.svg" alt="icon telephon" />
                            <div>
                                <h3 className="text-lg font-bold">+1 307-322-1946</h3>
                                <p className="text-base text-[#A3A3A3] font-medium">Phone number</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="px-16 py-16" ref={aboutRef}>
                    <div className="relative flex items-center">
                        <div className="relative">
                            <img className="w-[1500px]" src="/image/warungdenwafi/rectangle-about.png" alt="Background Rectangle" />
                            <img className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[350px] z-10" src="/image/warungdenwafi/food-about.png" alt="About Us" />
                        </div>
                        <div className="ml-12">
                            <h2 className="text-4xl font-bold text-[#121212] mb-4">About <span className="text-[#397E08]">Us</span></h2>
                            <p className="mb-8 font-medium text-lg">
                                Welcome to WarungDenWafi! We are dedicated to bringing you the authentic flavors of Indonesia with our
                                carefully crafted dishes. Our talented chefs use fresh ingredients and traditional recipes to ensure every
                                meal is a delightful experience. Whether dining in or taking out, we strive to make every visit
                                unforgettable. Thank you for choosing WarungDenWafi!
                            </p>
                            <button className="text-[#397E08] font-semibold outline outline-[#397E08] outline-2 px-6 py-3 rounded-full">Read More!</button>
                        </div>
                    </div>
                </section>


                <section className="px-8 py-16" ref={menuRef}>
                    <div className="text-center">
                        <h2 className="text-3xl font-bold mb-20">Most Popular Menu</h2>
                        <div className="flex justify-around">
                            <div className="relative w-1/4">
                                <img className="absolute -top-5 left-1/2 transform -translate-x-1/2 w-40 object-cover rounded-full z-10" src="/image/warungdenwafi/mostpopuler-1.png" alt="Menu 1" />
                                <div className="bg-white h-[270px] pt-24 px-7 rounded-2xl shadow-md mt-16">
                                    <h3 className="text-xl font-bold">Gudeg Rice Plate</h3>
                                    <p className="text-sm font-medium text-[#737373] pt-2">Perfect for a hearty and flavorful meal.</p>
                                    <p className="text-lg font-semibold text-[#ef4444] pt-4">$10.99</p>
                                </div>
                            </div>
                            <div className="relative w-1/4">
                                <img className="absolute -top-5 left-1/2 transform -translate-x-1/2 w-48 object-cover rounded-full z-10" src="/image/warungdenwafi/mostpopuler-2.png" alt="Menu 2" />
                                <div className="bg-white h-[270px] pt-24 px-7 rounded-2xl shadow-md mt-16">
                                    <h3 className="text-xl font-bold">Spicy Basil Shrimp Rice Plate</h3>
                                    <p className="text-sm font-medium text-[#737373] pt-2">Enjoy the zesty flavors of stir-fried shrimp with spicy basil.</p>
                                    <p className="text-lg font-semibold text-[#ef4444] pt-4">$12.99</p>
                                </div>
                            </div>
                            <div className="relative w-1/4">
                                <img className="absolute -top-5 left-1/2 transform -translate-x-1/2 w-40 object-cover rounded-full z-10" src="/image/warungdenwafi/mostpopuler-3.png" alt="Menu 3" />
                                <div className="bg-white h-[270px] pt-24 px-7 rounded-2xl shadow-md mt-16">
                                    <h3 className="text-xl font-bold">Nasi Lemak</h3>
                                    <p className="text-sm font-medium text-[#737373] pt-2">A perfect harmony of flavors and textures.</p>
                                    <p className="text-lg font-semibold text-[#ef4444] pt-4">$9.99</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="px-16 pt-16 pb-48" ref={reviewRef}>
                    <div className="">
                        <div className="flex justify-between pb-8">
                            <div>
                                <h2 className="text-3xl font-bold">Review</h2>
                                <p className="">See some customer reviews.</p>
                            </div>
                            <div className="space-x-2">
                                <button className="bg-[#d4d4d4] px-4 py-3 rounded-full">
                                    <svg className="w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="#ffffff" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /></svg>
                                </button>
                                <button className="bg-[#397E08] px-4 py-3 rounded-full">
                                    <svg className="w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="#ffffff" d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg>
                                </button>
                            </div>
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="bg-white p-4 w-96 h-40 rounded-lg shadow-md text-left space-y-3">
                                <div className="flex items-center gap-3">
                                    <img className="w-11" src="/image/avatar.webp" alt="" />
                                    <div className="flex flex-col -space-y-1">
                                        <span className="text-base font-semibold">Charles Patterson</span>
                                        <div className="flex items-center">
                                            <span className="text-xl text-[#facc15]">★★★★</span>
                                            <span className="text-xl text-[#d4d4d4]">★</span>
                                        </div>
                                    </div>
                                </div>
                                <p className="text-sm">The recipes offered are varied, from local to international cuisine, it's all here.</p>
                            </div>
                            <div className="bg-white p-4 w-96 h-40 rounded-lg shadow-md text-left space-y-3">
                                <div className="flex items-center gap-3">
                                    <img className="w-11" src="/image/avatar.webp" alt="" />
                                    <div className="flex flex-col -space-y-1">
                                        <span className="text-base font-semibold">Andrew Rathore</span>
                                        <span className="text-xl text-[#facc15]">★★★★★</span>
                                    </div>
                                </div>
                                <p className="text-sm">The easy and well-structured navigation makes it easy for me to find the information I need quickly.</p>
                            </div>
                            <div className="bg-white p-4 w-96 h-40 rounded-lg shadow-md text-left space-y-3">
                                <div className="flex items-center gap-3">
                                    <img className="w-11" src="/image/avatar.webp" alt="" />
                                    <div className="flex flex-col -space-y-1">
                                        <span className="text-base font-semibold">Bruce Hardy</span>
                                        <span className="text-xl text-[#facc15]">★★★★★</span>
                                    </div>
                                </div>
                                <p className="text-sm">The easy and well-structured navigation makes it easy for me to find the information I need quickly.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <footer className="bg-[#397E08] text-white px-16 py-8">
                    <div className="flex flex-col items-center text-center px-80 pb-9 space-y-3">
                        <h1 className="text-2xl font-semibold">WarungDenWafi</h1>
                        <p className="text-sm">WarungDenWafi serves authentic Balinese flavors with fresh and quality ingredients. We are dedicated to providing an unforgettable culinary experience.</p>
                    </div>
                    <div className="flex justify-between">
                        <p className="">© 2024 All rights reserved.</p>
                        <div className="flex justify-center space-x-4">
                            <svg className="w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#ffffff" d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z" /></svg>
                            <svg className="w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#ffffff" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></svg>
                            <svg className="w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#ffffff" d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z" /></svg>
                            <svg className="w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="#ffffff" d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z" /></svg>
                            <svg className="w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#ffffff" d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" /></svg>
                        </div>
                    </div>
                </footer>
            </div>
            <div className="font-jakartasans max-w-7xl mx-auto w-screen min-[1120px]:hidden" data-aos='fade-up'>
                <div className="flex flex-col items-center justify-center min-h-screen space-y-4">
                    <img className="w-60" src="/ilustrasi/dekstop.svg" alt="Desktop Illustration" />
                    <h1 className="font-semibold text-2xl">For now, only for desktop</h1>
                    <div className="text-center font-medium text-[#404040] text-lg space-y-1">
                        <p>for the upcoming mobile</p>
                        <p>Thanks🙏</p>
                    </div>
                    <Link to="/project/warungdenwafi" className="underline">Back</Link>
                </div>
            </div>
        </>
    )
}