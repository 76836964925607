export default function PromptAI({ sendDataCommand, isSending, message, setMessage }) {
    return (
        <div className='py-3 flex gap-2 w-full'>
            <input
                placeholder="Type a message"
                className="w-full h-10 text-sm px-4 py-2 border border-[#ccc] rounded-md dark:border-none dark:bg-[#262626] dark:text-[#fafafa] focus:outline-none focus:ring-0 focus:border-[#ccc] dark:focus:border-none"
                type="text"
                value={message}
                onChange={e => setMessage(e.target.value)}
            />
            <button className='bg-[#525252] dark:bg-[#262626] rounded-[10px] p-2' onClick={sendDataCommand} disabled={isSending} >
                <svg className='fill-[#fafafa]' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="m21.426 11.095-17-8A1 1 0 0 0 3.03 4.242l1.212 4.849L12 12l-7.758 2.909-1.212 4.849a.998.998 0 0 0 1.396 1.147l17-8a1 1 0 0 0 0-1.81z"></path></svg>
            </button>
        </div>
    )
}