import React, { useState, useEffect } from 'react';

export default function Footer() {
    const [year, setYear] = useState('');

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        setYear(currentYear.toString());
    }, []);

    return (
        <div className="max-w-6xl mx-auto px-8 mt-20">
            <hr className='border-[#121212] dark:border-[#404040]' />
            <div className="font-poppins text-sm my-3 flex justify-center max-[375px]:text-[11px] text-[#262626] dark:text-[#d4d4d4]">
                <h1>&copy;{year} Muhammad Wafi | All rights reserved</h1>
            </div>
        </div>
    );
}
