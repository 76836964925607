import React, { useState, useEffect } from "react";

export default function ThumbnailMovieUpcoming({ apikey }) {
    const [moviePopuler, setMoviePopuler] = useState([]);
    useEffect(() => {
        fetch('https://api.themoviedb.org/3/movie/popular?language=en-US&page=1', apikey)
            .then(response => response.json())
            .then(response => setMoviePopuler(response.results))
            .catch(err => console.error(err));
    }, []);

    return (
        <div className="flex flex-col justify-center mt-3">
            <div className="overflow-x-auto flex gap-3 py-3">
                {moviePopuler.map((res, index) => (
                    <>
                        <div className="flex-shrink-0">
                            <img key={res.id} className="w-40 rounded-2xl" src={`https://image.tmdb.org/t/p/w500${res.poster_path}`} alt={`image-${index + 1}`} />
                            <div className="space-y-1 mt-2">
                                <h1 className="w-36">{res.title}</h1>
                                <h3 className="text-[#a3a3a3] text-sm">{res.release_date}</h3>
                            </div>
                            {/*authors[index] && authors[index].map(author => (
                                <div key={author.id}>
                                    <p>Author: {author.author}</p>
                                </div>
                            ))*/}
                        </div>
                    </>
                ))}
            </div>
        </div>
    )
}