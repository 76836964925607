import Chat from '../index/chat'
import Header from '../component/header/header';
import Footer from '../component/footer/footer'

export default function PageHome() {
  return (
    <>
      <Header />
      <Chat />
      <Footer />
    </>
  );
}