import clsx from "clsx";
import { useState } from "react";
import RatingStars from "../rating";

export default function CardReview({rvw, author}) {
    const [Render, setRender] = useState(true);

    return (
        <div className="border py-4 px-4 w-fit rounded-lg flex-none flex-shrink-0 border-[#e5e5e5] dark:border-[#525252]">
            <div className="">
                <div className="flex gap-2 items-center">
                    <div
                        className={clsx(
                            "flex flex-col items-center space-y-2 justify-center",
                            Render ? 'animate-pulse' : '',
                        )}
                    >
                        <img
                            className={clsx(
                                "w-12 rounded-full max-[390px]:w-11",
                                Render ? 'scale-[1.02] blur-sm grayscale' : 'scale-100 blur-0 grayscale-0'
                            )}
                            src={rvw.data.photoURL}
                            alt="avatar"
                            onLoad={() => setRender(false)}
                            loading='lazy' />
                    </div>
                    <div className="items-center">
                        <div className="flex gap-[2px]">
                            <h1 className="text-sm font-[500] text-black dark:text-white">{rvw.data.displayName}</h1>
                            {rvw.data.email === author && (
                                <img alt='verified' className="w-[14px] mt-[3px] h-[14px] ml-1" src="/image/verified.png" />
                            )}
                        </div>
                        <div className="flex items-center gap-1">
                            <RatingStars rating={rvw.data.rating} />
                            <span className="text-[#737373]">•</span>
                            <p className="text-xs mt-[2px] text-[#737373]">{rvw.data.date}</p>
                        </div>
                    </div>
                </div>
            </div>
            <p className="mt-5 max-[375px]:text-[14px] max-[425px]:text-[15px] max-[600px]:text-[16px] text-black dark:text-white">{rvw.data.text}</p>
        </div>
    )
}