import Contact from '../index/contact'
import Header from '../component/header/header'
import Footer from '../component/footer/footer'

export default function PageHome() {
  return (
    <>
      <Header />
      <Contact />
      <Footer />
    </>
  );
}