import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../../api/firebase'

export default function ButtonGoogle({user}) {
    const handleGoogleLogin = async () => {
        const provider = new GoogleAuthProvider()
        try {
            await signInWithPopup(auth, provider)
            window.location.reload();
        } catch (error) {
            console.log(error)
        }
    }
    const handleGoogleLogout = async () => {
        try {
            auth.signOut()
            window.location.reload();
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            {user ? (
                <div className='flex gap-3 pt-4 max-[769px]:flex-col'>
                    <p className='text-sm text-black dark:text-[#a3a3a3]'>Signed in as <span className='font-[500]'>{user.displayName}</span> ({user.email})</p>
                    <div class="hidden text-sm rounded-full text-black dark:text-[#a3a3a3] md:block">•</div>
                    <button className='flex items-center gap-2 text-[#ef4444] rounded-[10px] text-sm' onClick={() => handleGoogleLogout()}>
                        <p>
                            <svg className='w-4' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#ef4444" d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z" /></svg>
                        </p>
                        Sign Out
                    </button>
                </div>
            ) : (
                <div className='pt-5 w-full space-y-2'>
                    <p className='text-sm text-center text-[#404040] dark:text-[#a3a3a3]'>Please sign in to typing</p>
                    <div className='flex justify-center'>
                        <button className='flex gap-3 font-[400] items-center rounded-lg bg-[#ffffff] px-4 py-2 font-sora text-[15px]  transition-all duration-300 dark:bg-[#fafafa] mb-2 mt-2 w-full justify-center border border-[#e5e5e5] shadow-sm hover:scale-[101%]' onClick={handleGoogleLogin}>
                            <svg className='w-6' stroke="currentColor" fill="currentColor" stroke-width="0" version="1.1" x="0px" y="0px" viewBox="0 0 48 48" enable-background="new 0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12
	c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24
	c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657
	C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36
	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571
	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path></svg>
                            <span className='text-sm'>Sign in with Google</span>
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}