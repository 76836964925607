import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Content from '../src/content';
import ButtonAI from '../src/component/FloatingButton/ButtonAI'

const content = ReactDOM.createRoot(document.getElementById('content'));
content.render(
  <React.StrictMode>
    <Content />
    <ButtonAI/>
  </React.StrictMode>
);