export default function RatingStars({ rating }) {
    const filledStars = Math.floor(rating);
    const partialStar = rating - filledStars !== 0;
    const emptyStars = 5 - filledStars - (partialStar ? 1 : 0);

    return (
        <div className="flex items-center">
            {[...Array(filledStars)].map((_, index) => (
                <svg
                    key={index}
                    className="w-5 fill-[#facc15]"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                >
                    <path d="M12 2l2.356 7.706h7.644l-6.213 4.786 2.357 7.706L12 17.412 5.856 21.198l2.357-7.706L1 9.706h7.644z" />
                </svg>
            ))}
            {partialStar && (
                <svg
                    className="w-5 fill-[#facc15]"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                >
                    <path d="M12 2l2.356 7.706h7.644l-6.213 4.786 2.357 7.706L12 17.412 5.856 21.198l2.357-7.706L1 9.706h7.644z" />
                </svg>
            )}
            {[...Array(emptyStars)].map((_, index) => (
                <svg
                    key={index}
                    className="w-5 fill-[#a3a3a3]"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                >
                    <path d="M12 2l2.356 7.706h7.644l-6.213 4.786 2.357 7.706L12 17.412 5.856 21.198l2.357-7.706L1 9.706h7.644z" />
                </svg>
            ))}
        </div>
    );
};