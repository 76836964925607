import React, { useState, useEffect, useRef } from "react";
import { useParams, Link } from 'react-router-dom';
import { GoogleAuthProvider, onAuthStateChanged, signInWithPopup } from 'firebase/auth'
import { getFirestore, onSnapshot, collection, addDoc, orderBy, query, doc, updateDoc, getDoc } from 'firebase/firestore'
import { auth, app } from '../../../api/firebase'
import AOS from 'aos';
import 'aos/dist/aos.css';
import ButtonGoogle from "../ButtonGoogle";
import CardReview from "../DetailProject/CardReview";
import { Helmet } from "react-helmet";
import ImageProject from "../DetailProject/ImageProject";
import ToolTip from "./ToolTip";
import UiCategories from "./UiCategories";
import { DownloadButton } from "../../../utils/download";
AOS.init();

const db = getFirestore(app)

const DetailProject = ({ linkthumb, title, content, datepublish, srctt1, srctt2, srctt3, srctt4, srctt5, namett1, namett2, namett3, namett4, namett5, showtt1, showtt2, showtt3, showtt4, showtt5, srcip1, srcip2, srcip3, srcip4, srcip5, srcip6, srcip7, srcip8, srcip9, srcip10, srcip11, srcip12, srcip13, srcip14, srcip15, showip1, showip2, showip3, showip4, showip5, showip6, showip7, showip8, showip9, showip10, showip11, showip12, showip13, showip14, showip15, intro1, intro2, intro3, asset, iconuc1, iconuc2, iconuc3, iconuc4, iconuc5, iconuc6, iconuc7, iconuc8, iconuc9, iconuc10, ctg1, ctg2, ctg3, ctg4, ctg5, ctg6, ctg7, ctg8, ctg9, ctg10, showuc1, showuc2, showuc3, showuc4, showuc5, showuc6, showuc7, showuc8, showuc9, showuc10, tag1, tag2, tag3, linkto1, linkto2, linkto3, fileName1, active1, active2, active3 }) => {
    const [isFixed, setIsFixed] = useState(false);
    const [user, setUser] = useState(null)
    const [like, setLike] = useState(false);
    const [click, setClick] = useState(false);
    const reviewRef = useRef(null);
    const [totalReview, setTotalReview] = useState(0);
    const [review, setReview] = useState([])
    const [seeLove, setSeeLove] = useState()
    const { projectId } = useParams();
    const author = process.env.REACT_APP_AUTHOR;
    useEffect(() => {
        const index = query(collection(db, projectId), orderBy("timestamp"))
        const unreview = onSnapshot(index, snapshot => {
            setReview(snapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
            setTotalReview(snapshot.size);
        })
        return unreview
    }, [])
    useEffect(() => {
        const getLikeValue = async () => {
            try {
                const path = doc(db, projectId, 'like');
                const resultPath = await getDoc(path);
                if (resultPath.exists) {
                    const likeValue = resultPath.data().like;
                    setSeeLove(likeValue);
                }
            } catch (error) {
                console.error(error);
            }
        };
        getLikeValue();
    }, []);
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const targetElement = document.getElementById('viewport');
            const targetOffsetTop = targetElement.offsetTop;

            setIsFixed(scrollTop >= targetOffsetTop);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    onAuthStateChanged(auth, user => {
        if (user) {
            setUser(user)
        } else {
            setUser(null)
        }
    })
    const scrollToReview = () => {
        reviewRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    const handleLike = async () => {
        try {
            const provider = new GoogleAuthProvider()
            if (!user) {
                await signInWithPopup(auth, provider)
            }
            const docRef = doc(db, projectId, 'like');
            const docSnap = await getDoc(docRef);
            if (!docSnap.exists()) {
                const dataToSend = {
                    like: '1'
                };
                await addDoc(docRef, dataToSend);
            } else {
                const newLikeCount = docSnap.data().like;
                const resultLike = parseInt(newLikeCount) + 1;
                const addGet = seeLove + 1
                setSeeLove(addGet)
                await updateDoc(docRef, { like: resultLike });
            }
        } catch (error) {
            console.log(error);
        }
        if (!click) {
            setLike(true);
            setClick(true);
        }
    };

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={content} />
            </Helmet>
            <div className="relative">
                <div className="bg-cover bg-center h-60 opacity-20 max-[550px]:h-48" style={{ backgroundImage: `url(${linkthumb})` }}></div>
                <hr className='border-[#d1d5db] dark:border-[#404040]' />
                <div className="absolute inset-0 py-12 px-8 max-w-6xl mx-auto">
                    <Link className="flex gap-2 font-normal text-[#525252] hover:gap-3 transition-all duration-300 dark:text-[#E4E6EB]" to='/project'>
                        <svg className="mt-[1px]" stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" data-testid="back-icon" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="10"></circle><polyline points="12 8 8 12 12 16"></polyline><line x1="16" y1="12" x2="8" y2="12"></line></svg>
                        <span>Back</span>
                    </Link>
                    <div id="viewport" className="mt-14 max-[550px]:mt-7">
                        <div className={`${isFixed ? 'px-8 py-5 fixed top-0 left-0 w-full bg-white shadow-md z-10 transition-all duration-300 dark:bg-black' : ''}`}>
                            <h2 className="text-[#121212] text-4xl font-semibold max-[600px]:text-[33px] max-[550px]:text-[28px] max-[480px]:text-[25px] max-[435px]:text-[21px] max-[370px]:text-[20px] dark:text-[#fafafa]">{title}</h2>
                        </div>
                        <p className="font-normal text-[#525252] mt-5 max-[550px]:mt-2 max-[550px]:text-[15px] max-[480px]:text-[14px] max-[435px]:text-[13px] dark:text-[#a3a3a3]">Published on <span className="font-medium">{datepublish}</span></p>
                    </div>
                </div>
            </div>
            <div className="flex justify-between items-center py-6 px-8 max-w-6xl mx-auto">
                <div className="space-y-5">
                    <div className="flex items-center gap-3">
                        <span className="text-[#737373] dark:text-[#a3a3a3]">Tools :</span>
                        <ToolTip src={srctt1} name={namett1} show={showtt1} />
                        <ToolTip src={srctt2} name={namett2} show={showtt2} />
                        <ToolTip src={srctt3} name={namett3} show={showtt3} />
                        <ToolTip src={srctt4} name={namett4} show={showtt4} />
                        <ToolTip src={srctt5} name={namett5} show={showtt5} />
                    </div>
                </div>
                <div>
                    <div className="flex items-center gap-4">
                        <button className="flex group items-center gap-2 border border-[#e5e5e5] dark:border-[#525252] px-4 py-1 rounded-full" onClick={handleLike} disabled={click}>
                            <span className="text-[#737373] dark:text-[#fafafa]">{seeLove}</span>
                            <svg className={`w-4 fill-[#737373] dark:fill-white ${like ? 'hidden' : 'block'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20c0 0-.1-.1-.1-.1c0 0 0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z" /></svg>
                            <svg className={`w-4 fill-[#f43f5e] ${like ? 'block' : 'hidden'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z" /></svg>
                        </button>
                        <button onClick={scrollToReview} className="flex items-center gap-2 px-4 py-1 rounded-full border border-[#e5e5e5] dark:border-[#525252]">
                            <span className="text-[#737373] max-[430px]:text-[16px] dark:text-[#fafafa]">{totalReview}</span>
                            <svg className="w-4 fill-[#737373] dark:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M160 368c26.5 0 48 21.5 48 48v16l72.5-54.4c8.3-6.2 18.4-9.6 28.8-9.6H448c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H64c-8.8 0-16 7.2-16 16V352c0 8.8 7.2 16 16 16h96zm48 124l-.2 .2-5.1 3.8-17.1 12.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V474.7v-6.4V468v-4V416H112 64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L208 492z" /></svg>
                        </button>
                    </div>
                </div>
            </div>
            <div className="flex overflow-x-auto px-8 max-w-6xl mx-auto gap-3">
                <ImageProject src={srcip1} show={showip1} />
                <ImageProject src={srcip2} show={showip2} />
                <ImageProject src={srcip3} show={showip3} />
                <ImageProject src={srcip4} show={showip4} />
                <ImageProject src={srcip5} show={showip5} />
                <ImageProject src={srcip6} show={showip6} />
                <ImageProject src={srcip7} show={showip7} />
                <ImageProject src={srcip8} show={showip8} />
                <ImageProject src={srcip9} show={showip9} />
                <ImageProject src={srcip10} show={showip10} />
                <ImageProject src={srcip11} show={showip11} />
                <ImageProject src={srcip12} show={showip12} />
                <ImageProject src={srcip13} show={showip13} />
                <ImageProject src={srcip14} show={showip14} />
                <ImageProject src={srcip15} show={showip15} />
            </div>

            <div className="pt-9 pb-10 flex space-x-10 font-medium text-[20px] px-8 max-w-6xl mx-auto max-[600px]:flex-col max-[600px]:space-x-0 max-[600px]:space-y-6">
                <div className="flex-1 space-y-6">
                    <div className="space-y-4">
                        <h1 className="text-black dark:text-white">Introduce</h1>
                        <div className="space-y-5">
                            <p className="text-sm font-normal dark:text-[#a3a3a3]">{intro1}</p>
                            <p className="text-sm font-normal dark:text-[#a3a3a3]">{intro2}</p>
                            <p className="text-sm font-normal dark:text-[#a3a3a3]">{intro3}</p>
                        </div>
                    </div>
                    <div className="space-y-4">
                        <h1 className="text-black dark:text-white">Assets :</h1>
                        <div className="space-y-5">
                            <p className="text-sm font-normal dark:text-[#a3a3a3]">{asset}</p>
                        </div>
                    </div>
                </div>
                <div className="flex-2 space-y-4">
                    <h1 className="text-black dark:text-white">User Interface categories :</h1>
                    <p className="text-sm font-normal">
                        <ul class="list-disc list-inside space-y-2">
                            <UiCategories icon={iconuc1} categories={ctg1} showuc={showuc1} />
                            <UiCategories icon={iconuc2} categories={ctg2} showuc={showuc2} />
                            <UiCategories icon={iconuc3} categories={ctg3} showuc={showuc3} />
                            <UiCategories icon={iconuc4} categories={ctg4} showuc={showuc4} />
                            <UiCategories icon={iconuc5} categories={ctg5} showuc={showuc5} />
                            <UiCategories icon={iconuc6} categories={ctg6} showuc={showuc6} />
                            <UiCategories icon={iconuc7} categories={ctg7} showuc={showuc7} />
                            <UiCategories icon={iconuc8} categories={ctg8} showuc={showuc8} />
                            <UiCategories icon={iconuc9} categories={ctg9} showuc={showuc9} />
                            <UiCategories icon={iconuc10} categories={ctg10} showuc={showuc10} />
                        </ul>
                    </p>
                </div>
            </div>
            <div className="flex justify-between items-center font-medium text-[20px] px-8 max-w-6xl mx-auto max-[900px]:flex-col max-[900px]:items-start max-[900px]:space-y-4">
                <div>
                    <h1 className="text-black dark:text-white">Tags :</h1>
                    <div className="flex gap-2 text-[12px] text-white pt-4">
                        <h1 className='flex justify-center bg-[#525252] py-[2px] px-[10px] rounded-xl dark:bg-transparent border dark:border-[#525252]'>{tag1}</h1>
                        <h1 className='flex justify-center bg-[#525252] py-[2px] px-[10px] rounded-xl dark:bg-transparent border dark:border-[#525252]'>{tag2}</h1>
                        <h1 className='flex justify-center bg-[#525252] py-[2px] px-[10px] rounded-xl dark:bg-transparent border dark:border-[#525252]'>{tag3}</h1>
                    </div>
                </div>
                <div className="space-y-4">
                    <h1 className="text-black dark:text-white">Link :</h1>
                    <div className="min-[500px]:flex items-center min-[500px]:gap-5 text-black dark:text-white grid max-[500px]:space-y-4 max-[500px]:grid-cols-2 max-[400px]:grid-cols-1">
                        <DownloadButton fileUrl={linkto1} fileName={fileName1} className={`flex items-center gap-3 flex-shrink-0 ${active1}`}>
                            <svg className="w-5 fill-black dark:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" /></svg>
                            <span className="font-medium text-sm">Download Design</span>
                        </DownloadButton>
                        <Link to={linkto2} className={`flex items-center gap-3 flex-shrink-0 ${active2}`}>
                            <svg className="w-5 fill-black dark:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3 .3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5 .3-6.2 2.3zm44.2-1.7c-2.9 .7-4.9 2.6-4.6 4.9 .3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3 .7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3 .3 2.9 2.3 3.9 1.6 1 3.6 .7 4.3-.7 .7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3 .7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3 .7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z" /></svg>
                            <span className="font-medium text-sm">Source Code</span>
                        </Link>
                        <Link to={linkto3} className={`flex items-center gap-3 flex-shrink-0 ${active3}`}>
                            <svg className="w-4 fill-black dark:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" /></svg>
                            <span className="font-medium text-sm">Live Demo</span>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="px-8 max-w-6xl mx-auto py-8">
                <hr className='border-[#d1d5db] dark:border-[#404040]' />
            </div>
            <div ref={reviewRef} className="space-y-5 px-8 max-w-6xl mx-auto">
                <div className="font-medium text-[20px] text-black dark:text-white">
                    <h1>{totalReview} Review</h1>
                </div>
                <div className="flex gap-3 overflow-x-auto py-3">
                    {review.map((rvw) => (
                        <CardReview
                            rvw={rvw}
                            author={author}
                        />
                    ))}
                </div>
                <ButtonGoogle user={user} />
            </div>
        </>
    )
}
export default DetailProject;