export default function CardChatSkeleton() {
    return (
        <div class="relative flex animate-pulse py-4 gap-2">
            <div class="h-12 w-12 rounded-full bg-[#d4d4d4] dark:bg-[#404040]"></div>
            <div class="flex-1 space-y-1 py-[1px]">
                <div class="mb-1 h-5 w-96 rounded-lg bg-[#d4d4d4] text-lg dark:bg-[#404040] max-[730px]:w-52"></div>
                <div class="h-5 w-48 rounded-lg bg-[#d4d4d4] text-sm dark:bg-[#404040] max-[730px]:w-24"></div>
            </div>
        </div>
    )
}