import React, { useEffect, useState } from "react";
import { mode } from '../header/mode';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import clsx from "clsx";
import 'aos/dist/aos.css';
AOS.init();

function toggleMenu() {
    const hamburger = document.querySelector('#hamburger');
    const navLinks = document.querySelector('#nav-links')
    const navLinksLi = document.querySelectorAll('#nav-li')
    navLinks.classList.toggle('navOpen')
    navLinksLi.forEach(link => {
        link.classList.toggle("fade");
    });
    hamburger.classList.toggle('hamburger-active');
};

function navClose() {
    const navLinks = document.querySelector('#nav-links')
    const hamburger = document.querySelector('#hamburger');
    navLinks.classList.remove('navOpen')
    hamburger.classList.remove('hamburger-active')
}

export default function Header() {
    const [Render, setRender] = useState(true);

    return (
        <>
        <div className="flex justify-center"> 
            <header class="max-w-6xl absolute z-20 py-6 w-full mx-auto flex justify-between items-center max-[769px]:w-full min-[769px]:dark:bg-transparent max-[769px]:fixed max-[769px]:z-[20] max-[769px]:py-5 max-[769px]:border-b max-[769px]:border-[#d1d5db] max-[769px]:dark:border-[#404040]">
                <div class="flex items-center gap-2 pl-5 z-[10]">
                    <div
                        className={clsx(
                            "Avatar",
                            Render ? 'animate-pulse' : ''
                        )}
                    >
                        <img
                            src="icon/logofilm.svg"
                            alt="avatar"
                            className={clsx(
                                "w-14 duration-700 ease-in-out max-[375px]:w-10 max-[769px]:w-11",
                                Render ? 'scale-[1.02] blur-sm grayscale' : 'scale-100 blur-0 grayscale-0'
                            )}
                            onLoad={() => setRender(false)}
                            loading="lazy" />
                    </div>
                    <div class="Name ml-2 text-gray-900">
                        <h1 class="font-sora text-lg font-medium max-[375px]:text-[12px] max-[600px]:text-[14px] text-[#fafafa]">Mirsani MV</h1>
                    </div>
                </div>
                <nav id="nav-menu" className="max-[769px]:absolute">
                    <ul id="nav-links" class="py-0 font-sora font-normal text-sm flex items-center gap-12 text-[#fafafa] max-[769px]:pt-20 max-[769px]:bg-[#fafafa] max-[769px]:font-normal max-[769px]:text-[16px] dark:text-[#e5e5e5] dark:max-[769px]:bg-[#121212]">
                        <li onClick={navClose} id="nav-li" className="max-[769px]:opacity-0"><Link to="/">Home</Link></li>
                        <li onClick={navClose} id="nav-li" className="max-[769px]:opacity-0"><Link to="/project">Movies</Link></li>
                        <li onClick={navClose} id="nav-li" className="max-[769px]:opacity-0"><Link to="/about">News</Link></li>
                        <li onClick={navClose} id="nav-li" className="max-[769px]:opacity-0"><Link to="/contact">Blog</Link></li>
                        <li onClick={navClose} id="nav-li" className="max-[769px]:opacity-0"><Link to="/chat">About</Link></li>
                    </ul>
                </nav>
                <div className="max-[768px]:flex max-[768px]:items-center max-[768px]:z-[12]">
                    <div className="pr-5 max-[375px]:mr-8 max-[768px]:mr-10">
                        <svg className="w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#fafafa" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" /></svg>
                    </div>
                    <button
                        aria-label="hamburger"
                        onClick={toggleMenu}
                        id="hamburger"
                        className="block min-[769px]:hidden absolute z-[11] right-[20px] max-[375px]:right-[16px]">
                        <span className="hamburger-line bg-[#121212] w-[27px] h-[3px] max-[375px]:w-[23px] dark:bg-[#fafafa]"></span>
                        <span className="hamburger-line bg-[#121212] w-[27px] h-[3px] max-[375px]:w-[23px] dark:bg-[#fafafa]"></span>
                        <span className="hamburger-line bg-[#121212] w-[27px] h-[3px] max-[375px]:w-[23px] dark:bg-[#fafafa]"></span>
                    </button>
                </div>
            </header>
        </div>
        </>
    );
}