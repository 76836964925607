import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NextTopLoader from 'nextjs-toploader';
import Home from './page/home';
import Project from './page/project';
import About from './page/about'
import Contact from './page/contact'
import Chat from './page/chat'
import More from '../src/index/detail';
import Film from './index/film';
import WarungDenWafi from './index/warungdenwafi';
import CekKhodam from './index/cekkhodam';

export default function Content() {
    return (
        <>
            <NextTopLoader />
                <Router>
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='/project' element={<Project />} />
                        <Route path='/project/:projectId' element={<More />} />
                        <Route path='/about' element={<About />} />
                        <Route path='/contact' element={<Contact />} />
                        <Route path='/chat' element={<Chat />} />
                        <Route path='/film' element={<Film />} />
                        <Route path='/project/warungdenwafi/preview' element={<WarungDenWafi />} />
                        <Route path='/cekkhodam' element={<CekKhodam />} />
                    </Routes>
                </Router>
        </>
    )
}