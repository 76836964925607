import React, { useEffect, useState } from "react";
import { mode } from './mode';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import clsx from "clsx";
import 'aos/dist/aos.css';
AOS.init();

function toggleMenu() {
  const hamburger = document.querySelector('#hamburger');
  const navLinks = document.querySelector('#nav-links')
  const navLinksLi = document.querySelectorAll('#nav-li')
  navLinks.classList.toggle('navOpen')
  navLinksLi.forEach(link => {
    link.classList.toggle("fade");
  });
  hamburger.classList.toggle('hamburger-active');
};

function navClose() {
  const navLinks = document.querySelector('#nav-links')
  const hamburger = document.querySelector('#hamburger');
  navLinks.classList.remove('navOpen')
  hamburger.classList.remove('hamburger-active')
}

export default function Header() {
  const [Render, setRender] = useState(true);

  useEffect(() => {
    const darkToggle = document.querySelector('#dark-toggle');

    const setDarkToggle = () => {
      const isDarkTheme =
        localStorage.theme === 'dark' ||
        (!('theme' in localStorage) &&
          window.matchMedia('(prefers-color-scheme: dark)').matches);

      darkToggle.checked = isDarkTheme;
    };

    setDarkToggle();
  }, []);

  return (
    <>
      <header class="max-w-6xl relative z-50 mx-auto flex justify-between items-center py-6 max-[769px]:w-full bg-[#fafafa] min-[769px]:dark:bg-transparent max-[769px]:fixed max-[769px]:z-[20] max-[769px]:py-5 max-[769px]:border-b max-[769px]:border-[#d1d5db] max-[769px]:dark:border-[#404040] dark:bg-[#121212]">
        <div class="flex items-center pl-5 z-[10]">
          <div
            className={clsx(
              "Avatar",
              Render ? 'animate-pulse' : ''
            )}
          >
            <img
              src="image/avatar.webp"
              alt="avatar"
              className={clsx(
                "w-14 duration-700 ease-in-out max-[375px]:w-10 max-[769px]:w-11",
                Render ? 'scale-[1.02] blur-sm grayscale' : 'scale-100 blur-0 grayscale-0'
              )}
              onLoad={() => setRender(false)}
              loading="lazy" />
          </div>
          <div class="Name ml-2 text-gray-900">
            <h1 class="font-sora font-medium max-[375px]:text-[12px] max-[600px]:text-[14px] dark:text-[#fafafa]">Muhammad Wafi</h1>
            <h2 class="font-sora font-normal text-xs max-[375px]:text-[11px] max-[600px]:text-[12px] dark:text-[#fafafa]">UI/UX Engineer</h2>
          </div>
          <div>
            <img className=
              {clsx(
                "w-[14px] ml-[5px] mb-[17px] max-[375px]:w-3",
              )}
              src="image/verified.png"
              alt="verified"
              loading="lazy"
            />
          </div>
        </div>
        <nav id="nav-menu" className="max-[769px]:absolute">
          <ul id="nav-links" class="py-0 font-sora font-normal text-sm flex items-center gap-12 text-[#121212] max-[769px]:pt-20 max-[769px]:bg-[#fafafa] max-[769px]:font-normal max-[769px]:text-[16px] dark:text-[#e5e5e5] dark:max-[769px]:bg-[#121212]">
            <li onClick={navClose} id="nav-li" className="max-[769px]:opacity-0"><Link to="/">Home</Link></li>
            <li onClick={navClose} id="nav-li" className="max-[769px]:opacity-0"><Link to="/project">Project</Link></li>
            <li onClick={navClose} id="nav-li" className="max-[769px]:opacity-0"><Link to="/about">About</Link></li>
            <li onClick={navClose} id="nav-li" className="max-[769px]:opacity-0"><Link to="/contact">Contact</Link></li>
            <li onClick={navClose} id="nav-li" className="max-[769px]:opacity-0"><Link to="/chat">Chat</Link></li>
          </ul>
        </nav>
        <div className="max-[768px]:flex max-[768px]:items-center max-[768px]:z-[12]">
          <div className="pr-5 max-[375px]:mr-8 max-[768px]:mr-10">
            <input onClick={mode} type="checkbox" className="hidden" id="dark-toggle" />
            <label htmlFor="dark-toggle">
              <div className="flex h-6 w-11 cursor-pointer items-center rounded-full bg-gradient-to-r from-[#ADD8E6] via-[#ADD8E6] to-[#66CCFF] p-[3px] dark:from-[#000080] dark:to-[#ADD8E6]">
                <div className="toggle-circle h-5 w-5 rounded-full bg-white transition-all duration-300"></div>
              </div>
            </label>
          </div>
          <button
            aria-label="hamburger"
            onClick={toggleMenu}
            id="hamburger"
            className="block min-[769px]:hidden absolute z-[11] right-[20px] max-[375px]:right-[16px]">
            <span className="hamburger-line bg-[#121212] w-[27px] h-[3px] max-[375px]:w-[23px] dark:bg-[#fafafa]"></span>
            <span className="hamburger-line bg-[#121212] w-[27px] h-[3px] max-[375px]:w-[23px] dark:bg-[#fafafa]"></span>
            <span className="hamburger-line bg-[#121212] w-[27px] h-[3px] max-[375px]:w-[23px] dark:bg-[#fafafa]"></span>
          </button>
        </div>
      </header>
    </>
  );
}